import React, { useState, useEffect } from 'react';
import { Link} from 'react-router-dom';
import "../Admin/assete/css/adminheader.css";
import head_logo from "../assate/logo/LOGO.png";
import '@fortawesome/fontawesome-free/css/all.css';


const Adminheader = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  

  useEffect(() => {
    // Close the menu when clicking outside of it
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest('.mobile-menu')) {
        setMenuOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <>
      <div className='admin_head'>
        <div className='ad_logo'>
          <Link to="/Viewdetails">
            <img src={head_logo} alt=""/>
          </Link>
        </div>
        <div className='ad_hed_1'>
          {/* Hide menu links in mobile view */}
          <div className="desktop-menu">
          <Link to="/Viewdetails" style={{ marginRight: '15px', color: "darkblue",textDecoration:"none",fontSize:"15px",fontWeight:"600" }}>Contact Details</Link>
            <Link to="/Changepassword" style={{ marginRight: '15px', color: "darkblue",textDecoration:"none",fontSize:"15px",fontWeight:"600"  }}>Change Password</Link> 
            <Link to="/Login" style={{ color:"darkblue",textDecoration:"none",fontSize:"15px",fontWeight:"600"  }}>Logout</Link>
          </div>
          {/* Show menu icon in mobile view */}
          <div className="mobile-menu">
          <button className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <i className="fas fa-bars"></i>
            </button>
            {/* Dropdown menu for mobile view */}
            <div className="mobile-menu-dropdown" style={{ display: menuOpen ? '' : 'none' }}>
            <Link to="/Viewdetails" style={{ color: "#fff" }}>View details</Link>
              <Link to="/Changepassword" style={{ color: "#fff" }}>Change Password</Link>
              <Link to="/Login" style={{ color: "#fff" }}>Log Out</Link>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Adminheader;
