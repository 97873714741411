import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import "../assate/css/index.css";
import "../assate/css/menu_sidebar.css";
import "../assate/css/mobile_view.css";
import "../assate/css/service.css";
import reser from "../assate/icon/research.png";
import email_log from "../assate/icon/email.png";
import soft from "../assate/icon/software.png";
import web from "../assate/icon/web hosting.png";
import video_mark from "../assate/icon/video mark.png";
import mark from "../assate/icon/app mark.png";
import about_img from "../assate/newwebpic/banner5.png";

const Service = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='about_main_div'>
        <div className='about_main'>
            <img src={about_img} alt="" />
            <div className="content-overlay">
    <h1>Services</h1>
   
  </div>
        </div>
    </div>

<div className='ser_our_div'>
    <div className='ser_our_div_1'>
        <h2 data-aos="fade-down">Our Services</h2>
        <p data-aos="fade-down">SeaSense Softwares is an innovative technology company dedicated to providing cutting-edge solutions tailored for maritime industries worldwide. With a focus on leveraging the power of software development and data analytics, SeaSense aims to transform traditional maritime operations, enhancing efficiency, safety, and sustainability across the seas.</p>
        <p data-aos="fade-down">Our mission at SeaSense Softwares is to empower maritime industries with intelligent software solutions that optimize operations, streamline processes, and drive sustainable growth. We strive to be at the forefront of innovation, continually pushing boundaries to deliver unparalleled value to our clients.</p>
    </div>
</div>

    <div className='sub_content_imga'>
            <div className='sub_contant_div' >
                <div className='sub_contant_1'>
                    <h2 data-aos="fade-right">Our Master's Services</h2>
                    <p data-aos="fade-left">We've included a preview of some of our most popular Master's services below.</p>
                </div>
                </div>
               
              
                <div className='service_main_div ' >
                <div className='service_div MoveBottom'data-aos="fade-right" >
                    <img src={reser} alt="" className="rotate-360"/>
                    <h2>RESEARCH SERVICE</h2>
                    <p>SEA SENSE providing research assistance service to the research scholars of various domains from Topic selection to paper acceptance.</p>
                   <div className='remo_btn'>
                    {/* <button><Link to="/Services" style={{textDecoration:"none"}}>Read More</Link></button> */}
                   </div>
                </div>
                <div className='service_div' data-aos="fade-down">
                <img src={email_log} alt="" className="rotate-360"/>
                    <h2>E-MAIL SERVICES</h2>
                    <p>SEA SENSE is a leading Digital Company which provides bulk promotional and Transactional E-Mail service around globe with Templates.</p>
                    <div className='remo_btn'>
                    {/* <button><Link to="/Services" style={{textDecoration:"none"}}>Read More</Link></button> */}
                   </div>
                </div>
                <div className='service_div' data-aos="fade-left">
                <img src={soft} alt="" className="rotate-360"/>
                    <h2>SOFTWARE SERVICES</h2>
                    <p>SEA SENSE is a Software Development Company which provides best software services all over India.</p>
                    <div className='remo_btn'>
                    {/* <button><Link to="/Services" style={{textDecoration:"none"}}>Read More</Link></button> */}
                   </div>
                </div>
                </div>
                <br></br>
              
                <div className='service_main_div ' >
                <div className='service_div' data-aos="fade-right">
                <img src={web} alt="" className="rotate-360"/>
                    <h2>WEB HOSTING</h2>
                    <p>It is important for every company to have its own website so that it can act as a convenient platform where customers and clients can get to know everything.</p>
                    <div className='remo_btn'>
                    {/* <button><Link to="/Services" style={{textDecoration:"none"}}>Read More</Link></button> */}
                   </div>
                </div>
                <div className='service_div 'data-aos="fade-down">
                <img src={mark} alt="" className="rotate-360"/>
                    <h2>APPS MARKETING</h2>
                    <p>Useful Android apps can transform the operational efficiency of enterprises. Not only do they bring a very flexible feature.</p>
                    <div className='remo_btn'>
                    {/* <button><Link to="/Services" style={{textDecoration:"none"}}>Read More</Link></button> */}
                   </div>
                </div>
                <div className='service_div' data-aos="fade-left">
                <img src={video_mark} alt="" className="rotate-360"/>
                    <h2>VIDEO MARKETING</h2>
                    <p>The rapidly evolving technology has created new areas and ways that allow for effective promotion, marketing and advertising possibilities.</p>
                    <div className='remo_btn'>
                    {/* <button><Link to="/Services" style={{textDecoration:"none"}}>Read More</Link></button> */}
                   </div>
                </div>
                </div>
               
                </div>
                <div className='ser_div_1'>
                <div className='ser_div_cnt' data-aos="fade-down">
                    <h2>Advantage With Us For your PhD</h2>
                    <p>We seasense will help you out in all your PhD assistance and Guidance. We have experts who work for you 24/7. We provide 100% satisfied Guidance and work delivered on time. seasense makes your PhD degree stress-free. Make your PhD easy with seasense. We provide complete PhD Assistance from Admissions, thesis Writing, Guide Slot, Synopsis Writing, research methodology, Publication, and all research support in PhD.</p>
                </div>
                </div>

                <div className='pro_main_div'>
            
            <div className='pro_sub_div'>
              <h2  data-aos="fade-right">OUR PROJECTS</h2>
              <p data-aos="fade-left">Welcome to our esteemed service for a high-quality software implementation for research papers. With our expertise and dedication to excellence, we ensure that your research papers are equipped with the most advanced software solutions available. Whether you require data analysis tools, simulation software, or any other software implementation for your research, you can get all the coding resources instantly.</p>
            </div>
            <div className='pro_btn' data-aos="fade-down">
              <button > <Link to="/Phdtopicselect" style={{textDecoration:"none", color:"#fff"}}  >Click Here</Link> </button>
            </div>
          </div>
            
<div className='fin_ser_div_1'>
            <div className='ser_final_div' >
                <p data-aos="fade-down">Are you Struggling to continue your PhD research? We seasense will help you out in all the PhD Assistance and needs. Less money in your wallet, no worries for your PhD Assistance and Guidance payment can be done in Instalments. Wait!! Talk with our experts, they will analyze your research and the stage you are in and give the needed guidance and assistance to get your Doctorate. Our Experts will clarify all your queries and offer complete guidance and assistance regarding research and work on the basis of your research ideas. Research Implementation and methodology are explained to you by our experts in live demo Sessions. You can clear your doubts regarding the research in that session itself and can obtain your results.</p>
            </div>
            </div>
   
    <Footer/>
    </body>
    </>
  )
}

export default Service