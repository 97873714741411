import React from 'react';
import "../assate/css/plagiarism.css";
import plagiaris_pic from "../assate/new_image/pla.webp";
import pla_pic from "../assate/new_image/pl.jpg";
import Header from './Header';
import pla_pix_pic from "../assate/newwebpic/banner4.jpg";
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Plagiarism = () => {
  return (
   <>
   <body>
   <Header/>
   <div className='pla_he_co_pic'>
    <img src={pla_pix_pic} alt=""/>
    <div className='content-overlay'>
        <h1>PLAGIARISM CHECKING</h1>
    </div>
   </div>
    <div className='plagiarism_main_div'>
        <div className='plagiarism_main_div_1' data-aos="fade-up">
            <img src={plagiaris_pic} alt="" />
        </div>
        <div className='plagiaris_suv_div'>
            <h2 data-aos="fade-down" style={{color:"#066fb2"}}> PLAGIARISM CHECKING</h2>
            <p data-aos="fade-down">The top priority of any research scholar’s is to write a dissertation that is free from plagiarized information from other sources as this is the most hideous research error. Every research scholar strives to produce a professional paper while maintaining his or her integrity and dedication.</p>
            <p data-aos="fade-down">You just can't afford to submit substandard work, no matter what type of writer you are. You will not succeed if your content is riddled with grammatical errors and misspellings. Plagiarism can cause grades to suffer and dissertations can be deemed useless. For this reason, we at Seasense Softwares place great emphasis on working on dissertations and assignments to remove copied information and provide the most advanced methods of plagiarism detection and elimination of the problem without losing the research scholar's ideas.</p>
        </div>
    </div>
    <div className='plagrisam_sub_cont'>
        <p data-aos="fade-down">Our team at Seasense Softwares is well versed in removing plagiarism from your dissertation in order to give it a professional appearance. We will take your dissertation to the next level by focusing on the key areas where you are most likely to encounter difficulties. We offer a variety of anti-plagiarism packages to suit your needs. The process consists of analyzing your dissertation or material for plagiarized regions and removing them according to your specifications, using anti-plagiarism software to discover the locations of plagiarism.</p>
    </div>

   

    <div className='Plagiarism_card_main'>
        <div className='Plagiarism_card_div' data-aos="fade-down">
        <h2 >Plagiarism Free Research Papers</h2>
        <ul>
            <li>Plagiarism-Free Writing Services: Our knowledge content is indeed distinctive and novel.</li>
            <li>It also contributes to the existing limits of knowledge.. As a result, our developers have developed a strategy for selecting novel tools.</li>
            <li>We also provide our customers with unique information</li>
            <li>First of all, research is an ongoing activity that takes place all over the world. For journal writing services and PhD services, it is necessary to conduct plagiarism checks on the text.</li>
            <li>Obviously, it is checking for content duplication. We specialize in providing high quality, plagiarism-free writing services to academics.</li>
            <li>In fact, it checks the use of similar words in nature. In addition, content used by previous researchers around the world.</li>
            <li>In reality, it restores the originality of the content and allows the scholar to be proud of his intellectual achievements.</li>

        </ul>
        </div>
        <div className='Plagiarism_card_div' data-aos="fade-down">
            <h2>Steps for plagiarism detection</h2>
            <ul>
                <li>The candidate submits the original manuscript to our portal.</li>
                <li>We generate a plagiarism report using Turnitin. (a commercial, Internet-based plagiarism-detection service).</li>
                <li>If plagiarism is discovered, the candidate revises the manuscript.</li>
                <li>The candidate submits the revised manuscript to our plagiarism detection portal.</li>
                <li>If plagiarism is less than 10%, the candidate receives a grade.</li>
            </ul>
        </div>
    </div>
    <div className='plagr_final_div'>
        <div className='plag_pic' data-aos="fade-down">
            <img src={pla_pic} alt="" />
        </div>
<div className='Plagiarism_final'>
    <p data-aos="fade-down">we have tools like Turnitin, Ex Plagiarism for the process of plagiarism. As well as, Grammar Checking. To be sure, it checks whether content is copied.</p>
<p data-aos="fade-down">To begin with, if plagiarism checking as a separate service is taken we assure it to be less than 10%. At the same time, we modify change alter the content to make it to that extent.</p>
<p data-aos="fade-down">Particularly, if we take up the writing service we have content of originality. As well as, novelty but still this would be checked and verified. Therefore, our developers have strategy in selecting innovative tools.</p>
<p data-aos="fade-down">We provide a plagiarism checker that covers the entire article. So that you can submit the papers (in Microsoft Word or other common file formats) and the instructor receives an originality report that shows any possible matches between the submitted paper and internet resources. It is important to remember that the presence of plagiarism in a research paper implies that the document was copied from another author's work. Scholars receive unique non-plagiarized research papers from our technical writing team. Additionally, for your PhD research. We will provide you with thorough assistance. To generate creative Thesis and Journals, we combine the latest technologies with complicated domains. In addition, we create original research articles and journals for research researchers. In fact, we're here to help you at every step of the way.</p>
</div>
</div>
<Whatapp/>
<Scrolltop/>
<Footer/>
</body>
   </>
  )
}

export default Plagiarism
