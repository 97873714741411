import React from 'react';
import "../assate/css/literature.css";
import lit_pic from "../assate/new_image/literature-review.jpeg";
import lite_pic_2 from "../assate/newwebpic/liter.png";
import lit_pic_div_1 from "../assate/newwebpic/banner4.jpg";
import Header from './Header';
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';
const Literature = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='lit_div_pic'>
        <img src={lit_pic_div_1} alt="" />
        <div className='content-overlay'>
            <h1>LITERATURE REVIEW</h1>
        </div>
    </div>
    <div className='lit_li_lo'>
    <h2 data-aos="fade-down" style={{color:"#066fb2"}}>LITERATURE REVIEW</h2>
        <p data-aos="fade-down">To begin with, a Literature review is the initial stage of any research writing for journal or thesis writing. In fact, it is the base of any research. For this purpose, it provides a total clear logical knowledge of the topic. Therefore, this is the initial work of any research. At the same time, it will be followed corresponding to your instructions. For this reason, your satisfaction is our first goal.</p>
    </div>
     <div className='literature_main_div'>
    <div className='Literature_main'>
      
        <p  data-aos="fade-down">It is an important element of your research and dissertation as it is the stage that you try to identify any learning gaps in your schooling and knowledge sources. At this level, you should read, understand, and analyze a number of research articles previously published in your field of study. The study material for the literature review is mostly determined by your topic selection and your base paper. In order to complete a literature review portion in their dissertation that is interesting and compelling enough most people refer to an average of 10 to 20 research publications.</p>
        <p data-aos="fade-down">To conclude Literature Review, Writing Assignment would have a recent analysis in to the developments and changes, topic per say. As well as, the related areas of research.</p>
    </div>
<div className='literature_pic' data-aos="fade-right">
    <img src={lit_pic} alt="" />
</div>

   </div>
   <div className='literature_sub_div'>
    <h2 data-aos="fade-down" style={{color:"#066fb2"}}>Typically there are 5 types of literature reviews</h2>
   <ul data-aos="fade-down">
    <li>Systematic literature reviews.</li>
    <li>Traditional literature reviews.</li>
    <li>Narrative literature reviews.</li>
    <li>Meta-synthesis.</li>
    <li>Meta-analysis.</li>
   </ul>
   </div>
   <div className='lite_fl_div'>
    <div className='lite_pid_pic' data-aos="fade-left">
        <img src={lite_pic_2} alt="" />
    </div>
   <div className='litr_div'>
   <p data-aos="fade-down">The first trouble faced in the literature review step is locating a group of relevant and high-quality papers and articles to review. In order to find such interesting and relevant research papers, one has to research the internet extensively. At Seasense Softwares, we have a team of professionals who specialize in research writing. They know where to look for relevant literature for your study topic and how to find it.</p>
   <p data-aos="fade-down">Second, writing a literature review with all the information needed in all of the appropriate places takes a lot of time and effort. At Seasense Softwares, we know what it takes to write a high quality literature review that will serve as the backbone of your research and dissertation. As a result, you can be assured that the responsibility for your literature review is in capable hands.</p>
   <p data-aos="fade-down">In fact, it is the backbone of any research writing for Journal or Writing Services, Research Proposals. For this reason, it provides a total clear logical understanding of the topic.</p>
   <p data-aos="fade-down">Therefore, this is the first preliminary starting work of any research report writing. At the same time, it will be followed according to your instructions. For this reason, your satisfaction is our first goal.</p>
   </div>
   </div>
   <div className='final_card_div_lit'>
    <div className='final_div_lit' data-aos="fade-down">
        <h2>This step further leads to</h2>
        <ul>
            <li>Synopsis and abstract of research.</li>
            <li>Research Methodology, Scope and limitations.</li>
            <li>To identify problem Problem identification and selection.</li>
            <li>Need and importance of the study.</li>
            <li>Need objectives, data collection as well as analysis.</li>
            <li>In this case, the identification of research gaps is important.</li>
        </ul>
    </div>
    <div className='final_div_lit' data-aos="fade-down">
    <h2>We support such an important research step in the following areas for Literature Review</h2>
    <ul>
        <li>In fact, to identification of related research on the topic.</li>
        <li>Downloading reading and finding gaps, in particular</li>
        <li>The innovative methodology and conclusions derived from the Literature Review</li>
        <li>Hence, all of this would be done systematically with purpose and focus by us. At the same time, Seasense Software offers written support in a format specified by universities around the world.</li>
        <li>A minimum of 10 years developments would be captured and presented for literature review. In fact, thematic developments and changes happened would also be presented. Hence, the Review would include national and international writings of repute and excellence.</li>
    </ul>
        </div>
   </div>
   <Whatapp/>
   <Scrolltop/>
   <Footer/>
   </body>
    </>
  )
}

export default Literature