import React from 'react';
import "../assate/css/language.css";
import lag_pic from "../assate/new_image/Language-Correction.jpg";
import lag_ad_pic from "../assate/new_image/german-language-correction-service.jpeg";
import Header from './Header';
import lag_pic_2 from "../assate/newwebpic/banner4.jpg";
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Language = () => {
  return (
   <>
   <Header/>
   <div className='lag_pic_div'>
    <img src={lag_pic_2} alt=""/>
    <div className='content-overlay'>
        <h1>LANGUAGE CORRECTION</h1>
    </div>
   </div>
    <div className='language_main_div'>
    <h2 data-aos="fade-down" style={{color:"#066fb2"}}>LANGUAGE CORRECTION</h2>
    <p data-aos="fade-down">Like many people, you probably took in the essential principles of syntax in school. What’s more, in the same way as other individuals, you’ve likely skipped quite a bit of what you realized. As a matter of fact, completing your sentences with a relational word an awful practice? Are there rigid regulations for when to utilize who, that or which? Where do the comma and punctuation go?</p>
    
   </div>
   <div className='language_sub_div'>
    <div className='language_sub_div_1'>
        <p data-aos="flip-down">As well as, the importance of language correction in PhD research work has never been greater than today. In fact, language correction is ending up perpetually aware of the importance of worldwide co-appointment as a wellspring of upper hand, and language persists a definitive hindrance to aspirations of global synchronization. Specifically, we will audit the work concerning language the board. In order to, we will talk about the previously mentioned pattern to globalization, plot the elements of the language barrier and outline its outcomes.</p>
    </div>
    <div className='language_pic' data-aos="fade-down">
        <img src={lag_pic} alt=""/>
    </div>
   </div>
   <div className='language_conte_div'>
    <h2 data-aos="fade-down" style={{color:"#066fb2"}}>Professional Language Editors</h2>
    <p  data-aos="fade-down">
Professional editors at Seasense Software are all highly trained writers, academics, graduate students that have extensive editing experience. They are all native English speakers, and we expect them to graduate from a respected university and have the best academic credentials. Our editors that help with ESL editing have a lot of experience and help people with English grammar. They are professionals at resolving ESL grammatical errors, correcting spelling, and providing world-class editing and proofreading services. Each of our editors is dedicated to helping you improve any work you submit. We set the time frame after talking with your instructors about your language-editing needs.</p>
   </div>
   <div className='lang_grd_div'>
   <div className='lang_add_pic' data-aos="fade-down">
    <img src={lag_ad_pic} alt="" />
   </div>
   <div className='langiage_correct_div'>
    
    <h2 data-aos="fade-down" style={{color:"#066fb2"}}>Our Language Correction includes</h2>
    <ul data-aos="fade-down">
        <li>Subject – verb agreement</li>
        <li>Spelling mistakes.</li>
        <li>Framing of sentences</li>
        <li>Appropriate usage of consecutive nouns</li>
        <li>Correctly spelled words are used in the wrong context</li>
    </ul>
   </div>
  
   </div>
   <div className='language_final'>
    <p  data-aos="fade-down">At Seasense Softwares, we review your entire document to ensure that even the most challenging papers on difficult subjects are completed to perfection.

A good English document should have proper syntax – making sure the words and phrases, correct spelling; grammar – structural use of clauses and words, checking common and uncommon phrases, sentence formation; punctuation – proper use of commas, periods, capital and lower-case letters.</p>
<p data-aos="fade-down">Since English is such a difficult language, it is important that your work be reviewed by someone who is both familiar with the subject and has excellent knowledge in English. Because research is more complicated, a single mistake can change the meaning of a sentence, and inaccurate information can be difficult for the reader to understand.</p>
   </div>
   <Whatapp/>
   <Scrolltop/>
   <Footer/>
   </>
  )
}

export default Language