import React from 'react'
import Header from './Header';
import jour from "../assate/sub_image/ban.jpg";
import "../assate/css/jouranl.css";
import jur_1 from "../assate/newwebpic/journal1.jpg";
import fin_piuc from "../assate/new_image/rese1.png"
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const JouranlAss = () => {
  return (
   <>
   <body>
   <Header/>
   <div className='jouranl_main'>
    <img src={jour} alt="" />
    <div className='content-overlay '>
        <h1>JOURNAL ASSISTANCE</h1>
    </div>
   </div>
    <div className='jon_m_div'>
    <h2 data-aos="fade-down">JOURNAL ASSISTANCE</h2>
           <p data-aos="fade-down">Journal Assistance services, the most popular and most sought of service of Sea Sense has worldwide reputation. We are providing Professional Journal Writing format for Engineering, Thesis Writing, Management, Arts & Sciences. In order to, we undertake services to write the entire content as per the International standard specifications. We also assist in publishing the same content in Scopus journal and in other International repute journals or any journal articles of your choice. Sea Sense provides end to end solutions in this domain, and we are proud to say that we are the established market leaders in the best quality Journal paper writing services for the last four decades.</p>
    </div>



   <div className='journal_div'>
       
       <div className='journal_main_div'>
           
           <p data-aos="fade-down">Every journal has its own structure and set of rules. It is required that the paper be submitted in the format stated. We support the researcher by complying with the requirements and formats of the journal.</p>
           <p data-aos="fade-down">The research results are published in international journals around the world, which is a crucial step in the research process. The majority of them consider conducting research to be a demanding task. Seasense supports you in the publication of your research article in well-known journals and provides crucial assistance.</p>
           <p data-aos="fade-down">The majority of researchers struggle to correct review comments after they are published. The Seasense research team will assist the researcher in modifying the article as recommended by the evaluator, and the Seasense team will make the necessary adjustments.</p>
       </div>
       <div className='jou_ppic' data-aos="fade-left">
               <img src={jur_1} alt="" />
           </div>
      </div>
      <div className='journal_card_div'>
       <div className='jouranl_card_main_div' data-aos="fade-down">
           <h2 >Our Journal services</h2>
           <p>Our journal finder connects you with specialists in your field who can help you choose the right journal.</p>
           <p>We will check your work thoroughly and, depending on the likelihood ofacceptance, send it to the 3-5 leading journals.</p>
           <p>Seasense will scan a broad international database of journals including SCI, SSCI, Web of Science, Scopus and others.</p>
       </div>
       <div className='jouranl_card_main_div' data-aos="fade-down">
           <h2>Our experienced peer reviewers will provide important feedback for your manuscript including</h2>
           <p>The language of the manuscript</p>
           <p>Performance Evaluation</p>
           <p>Publication readiness in its current state and required changes</p>
           <p>Manuscript length based on journal</p>
           <p>Findings and challenges</p>
           <p>Research novelty</p>
       </div>
       <div className='jouranl_card_main_div' data-aos="fade-down">
           <h2 >what Do You Get?</h2>
           <p>Provides a list of 3-5 carefully selected journals, each with more than 10 parameters, so you can choose the ideal journal to publish.</p>
           <p>Offers specific expert commentary on journals that can help improve the quality of your manuscript and / or speed up the publishing process.</p>
           <p>We help applicants publish their original research and survey-based work in Scopus and SCI indexed journals. Scopus and SCI indexed journals are peer-reviewed publications that cover scientific or academic topics to benefit the research community.
   </p>
       </div>
      </div>
      <div className='jouranl_fianla_div'>
       <div className='finbal_pic' data-aos="fade-right">
           <img src={fin_piuc} alt="" />
           </div>
   
       <div className='final_jouranla_div'>
           <h2 data-aos="fade-down">We make it easier for you to write papers in the following ways</h2>
      
       <div className='final_sib_journal_div'>
           <h2 data-aos="fade-down">Novelty in ideas:</h2>
           <p data-aos="fade-down">If similar work has already been published, we compare the proposed work with existing approaches in the area to identify the innovation in the work and suggest whether to change the write-up, rewrite the entire work, or discard the write-up.</p>
           <h2 data-aos="fade-down">English Language:</h2>
           <p data-aos="fade-down">We help with writing on paper by offering English language aids (grammar, vocabulary, sentence structure and tenses).</p>
           <h2 data-aos="fade-down">Manuscript:</h2>
           <p data-aos="fade-down">It should contain a title, information about the author, affiliations, abstract, keywords, main text, acknowledgments, references and, if applicable, figure / table and additions / supporting information. We help authors to prepare a paper that meets all the requirements for publication in peer-reviewed journals.</p>
           <h2 data-aos="fade-down">Publishing Ethics:</h2>
           <p  data-aos="fade-down">An author's passport to begin the researcher's journey is the publication of an article in an SCI or Scopus indexed journal. It reflects the ability of authors and organizations to produce high quality work. It is therefore important to agree on the publication of ethical guidelines. We support applicants in adhering to the publication ethics by offering a plagiarism check and analyzing the uniqueness of their work.</p>
           </div>
       </div>
      </div>
      <Whatapp/>
      <Scrolltop/>
  <Footer/>
  </body>
   </>
  )
}

export default JouranlAss