import React from 'react';
import { Link } from 'react-router-dom';
import {motion, useScroll} from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import "../assate/css/newanimation.css";
import "../assate/css/index.css";
import "../assate/css/mobile_view.css";
import "../assate/css/menu_sidebar.css";
import "../assate/css/newanimation.css";
import back_im from "../assate/new_image/offz2.jpg";
import days from "../assate/icon/1.png";
import lang from "../assate/icon/2.png";
import mba from "../assate/icon/3.png";
import phd from "../assate/icon/4.png";
import agre from "../assate/icon/5.png";
import asse from "../assate/icon/6.png";
import asses from "../assate/icon/7.png";
import wlcm_srh from "../assate/sub_image/WRITING WINNING PROPOSAL (4).png";
import Header from './Header';
import Footer from './Footer';
import sib_co_pic from "../assate/sub_image/WRITING WINNING PROPOSAL.png";
import adva_pic_3 from "../assate/sub_image/ad3.jpg";
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';
import vis_pic from "../assate/newwebpic/1.jpg";
import mdeol from "../assate/newwebpic/969266_awards_medal_achievement_award_best_icon.png"
import sta from "../assate/newwebpic/430117_star_icon.png"
import gaol from "../assate/newwebpic/2411798_achieve_target_aim_bullseye_goal_icon.png"
import CountUp from 'react-countup';
import email from "../assate/newwebpic/Untitled design.jpg";
import res from "../assate/newwebpic/res.jpg";
import soft from "../assate/newwebpic/soft.jpg";
import web from "../assate/newwebpic/web.jpg";
import apps from "../assate/newwebpic/apps.jpg";
import video from "../assate/newwebpic/video.jpg";
const Index = () => {
    
  

  const navigate = useNavigate();
  const phd_peoposal = () => {
    navigate("../contact");
    navigate("../Phdtopicselect");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const {scrollYProgress} =useScroll()
  

  return (
    
    <>
     <motion.div
      style={{scaleX:scrollYProgress,
        position:'fixed',
        top:0,
        right:0,
        left:0,
        height:1,
        background:"blue",
        transformOrigin:"0%",
    }}
    ></motion.div>
    <Header/>
    <body>
   
   
       
    <div className="background-image-container">
    <div className='wrapper'>
      <div className='box'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  <img src={back_im} alt="" className="background-image" />
</div>

          
        <div className='con-tob'>
        
        <div className='content-overlays'>
          <h1 data-aos="fade-left">WELCOME TO SEA SENSE</h1>
          <p data-aos="fade-down">We provide flawless Research Guidance to Research Scholars.</p>
          <div className='con_di'>
          <p data-aos="fade-left">We provide specialists software development service to help improve your business process and integrating different platform.we will design,develop,test and integrate softwareact across multiple platform included mobile application.</p>
          </div>
          <div className='hed_lo_di' >
            <button><Link to="../Contact" style={{textDecoration:"none" ,color:"#fff"}} >Contact</Link></button>
          </div>
        </div>
       
       
          </div>
         
      <div className='best_main_div'>
        <div className='best_sub_div'>
          <h2 data-aos="fade-up" >Best Phd Assistance In India</h2>
          <p data-aos="fade-down">Sea sense is a top <span style={{ color: '#066fb2' }}>PhD Assistance</span> service in India, known for its excellent support for academics in doctorate research. Our team has experienced PhD professionals with diverse academic backgrounds. Each scholar acquires specific instructions for their field of study. Sea sense provides comprehensive support  <span style={{color:"#066fb2"}} >project implementation</span> , and research papers, which includes developing  <span style={{color:"#066fb2"}} >research proposal </span>, building methodologies, and preparing manuscripts for publication. We prioritize high standards in data analysis. Our researchers use various methods to ensure accurate study results. Sea  sense is proud of researchers who submitted their <span style={{color:"#066fb2"}} >Thesis</span> and achieved publication, advancing their academic careers. Sea sense is a reliable academic advisor who values confidentiality and transparency. Clients could reach out to us to schedule a consultation for customized professional advice that meets their research needs.</p>
        </div>
      </div>
     
     
   
                <div className='welcom_main_div_1 ' >
           <h2 data-aos="fade-down">Top <span style={{color:"#066fb2"}}>PhD Service </span>OF <span style={{color:"#066fb2"}}>Sea Sense</span></h2>
           <div className='dd_phd'>
            <div className='wlcm_div_content'  >
             
              <p data-aos="fade-right">One Stop Solution for All Research Needs.
          Sea Sense works as an external guide to brainstorm your idea and translate that into research model. Hiring a instructor is common and therefore let your research committee known about the same. We do not offer any writing services without the participation of the research scholar, and therefore we are purely a translator for non-native English speakers and our services are simply restricted to research scholars to hasten their research process. We offer following services to research scholars across India:-Ideas to finding research Topic, Paper writing, editing, proofreading, plagiarism correction, coding and algorithm, illustration and formatting etc.</p>
          </div>
          <div className='wlcom_search' >
          <div  className='imgSec' data-aos="fade-left">
            <img src={wlcm_srh} alt=""/>
            </div>
         </div>
         </div>
            
           
           </div>

           
              <div className='fac_ser_main_div'>
                <div className='fac_div_la_ve'>
            <h2 data-aos="fade-down" style={{color:"#066fb2"}}>OUR SERVICES</h2>
           </div>
                <div className='fac_ser_main_div_1' data-aos="fade-down">
                  <div className='fac_main_div_pic'>
                    <div className='fac_pic_main_div'>
                      <img src={res} alt=""/>
                    </div>
                    <div className='fac_main_div_conmts'>
                      <h2>RESEARCH SERVICE</h2>
                      <p>SEA SENSE providing research assistance</p>
                     
                      
                     
                    </div>
                  </div>
                  <div className='fac_main_div_pic'>
                    <div className='fac_pic_main_div'>
                      <img src={email} alt=""/>
                    </div>
                    <div className='fac_main_div_conmts'>
                      <h2>E-MAIL SERVICES</h2>
                      <p>SEA SENSE is a leading Digital Company</p>
                    </div>
                  </div>
                  <div className='fac_main_div_pic'>
                    <div className='fac_pic_main_div'>
                      <img src={soft} alt=""/>
                    </div>
                    <div className='fac_main_div_conmts'>
                      <h2>SOFTWARE SERVICES</h2>
                      <p>SEA SENSE is a Software Development</p>
                    </div>
                  </div>
                </div>


                <br></br>
                <br></br>
                <div className='fac_ser_main_div_1' data-aos="fade-down">
                  <div className='fac_main_div_pic'>
                    <div className='fac_pic_main_div'>
                      <img src={web} alt=""/>
                    </div>
                    <div className='fac_main_div_conmts'>
                      <h2>WEB HOSTING</h2>
                      <p>SEA SENSE providing research assistance</p>
                    </div>
                  </div>
                  <div className='fac_main_div_pic'>
                    <div className='fac_pic_main_div'>
                      <img src={apps} alt=""/>
                    </div>
                    <div className='fac_main_div_conmts'>
                      <h2>APPS MARKETING</h2>
                      <p>SEA SENSE is a leading Digital Company</p>
                    </div>
                  </div>
                  <div className='fac_main_div_pic'>
                    <div className='fac_pic_main_div'>
                      <img src={video} alt=""/>
                    </div>
                    <div className='fac_main_div_conmts'>
                      <h2>VIDEO MARKETING</h2>
                      <p>SEA SENSE is a Software Development</p>
                    </div>
                  </div>
                </div>
              </div>
      
              <div className='call_fact_div'>
            <div className='call_fact_div_1' data-aos="fade-down">
              <h2>You can also send us an email and we’ll get in touch shortly,</h2>
              <h2>info@seasensesoftwares.com </h2>
            </div>
          </div>

          
          <div className='fun_vis'>
                <div className='fun_im' data-aos="fade-right">
                  <img src={vis_pic} alt=""/>
                </div>
                
               
                <div className='vis_co_li'>
                  <h2 data-aos="fade-down"  style={{color:"#066fb2"}}>ABOUT OUR COMPANY</h2>
                  <p data-aos="fade-down">Sea Sense aspires to the best research guidance provider in the world through its holistic approach irrespective of subjects, countries, and specializations.</p>
                  <p data-aos="fade-down">To achieve this vision, we approach each research through unique methodology and after a lot of discussion between research experts, professors, data management experts, industry professionals and language & technical editors.</p>
                  <div className='vi_mul_div' data-aos="fade-down">
                    <div className='vi_mul_div_1'>
                   <div className='star_ic'>
                    <img src={sta} alt=""/>
                   </div>
                      <h2>Vision</h2>
                      </div>
                      <div className='vi_mul_div_1'>
                      <div className='star_ic'>
                    <img src={mdeol} alt=""/>
                   </div>
                      <h2>Missions</h2>
                      </div>
                      <div className='vi_mul_div_1'>
                      <div className='star_ic'>
                    <img src={gaol} alt=""/>
                   </div>
                      <h2>Goals</h2>
                      </div>
                      
                   
                  </div> 
                  </div>
                 
                  </div>

             <br></br>
             <br></br>
             <div className='sy_pre'>
             <h2 data-aos="fade-down">SYNOPSIS PREPARATION</h2>
             </div>
             <div className='sub_co'>
              
          <div className='sub_co_div 'data-aos="fade-down"  >
            <img src={sib_co_pic} alt="" />
          </div>
          <div className='sub_co_cont_div ' data-aos="fade-down" >
          
            <p>Making a synopsis is a difficult task. A synopsis is a systematic summary or simplified version of anticipated research that is created before research begins. The research overview summarizes the overall design of the study. It defines why the research is being conducted, what the goals are, and how the data will be collected. It can also be used to collect and track guides and inquiries. Sea sense provides the best synopsis writing service to help you achieve your project goals.</p>
            <div className='sub_co_bt_div'>
            <button> <Link to="/Synopsis" style={{textDecoration:"none", color:"#fff"}}  onClick={phd_peoposal}>Click Here</Link></button>
          </div>
          </div>
         
        </div>
        <div className='ser_fact  fact_back'>
                    <div className='ser_fact_1'>
                        <p data-aos="fade-down">We provide high quality services & innovative  solutions for the realiable growth</p>
                      <div className='ser_btnn'>
                        <button><Link to="/Contact" style={{color:"#fff", textDecoration:"none"}} onClick={phd_peoposal}>Contact</Link></button>
                      </div>
                    </div>
                </div>

       
        
                {/* <div className="hero-header overflow-hidden px-5">
                  
                <div className="rotate-sty-2"></div>
                <div className='back_prin'>
               
            <div className='company_prin'>
             
            <div  className='text-container '>
              <div className='cmp_div'>
              <h2 data-aos="fade-down">COMPANY PRINCIPLES</h2>
                  <p data-aos="fade-down">We believe in making the maximal use of Technology for rendering the best to our Research Scholars at different levels.</p>
              </div>
              <div className='company_princples ' >
                
                  <div className='compyy_card_div' data-aos="fade-up">
                  <h2 >Commitment</h2>
                  <p>sea sense strongly believes in the principles of commitment as it builds a bridge for long term Relations.</p>
                 </div>
                 <div className='compyy_card_div' data-aos="fade-up">
                  <h2 >Diligence</h2>
                  <p>We have a reputation of being enthusiastic as we appreciate that no mission could be reached without dedication.</p>
                  </div>
                  <div className='compyy_card_div' data-aos="fade-up">
                  <h2 >Excellence</h2>
                  <p>It is enormously crucial to be ambitious for the growth. So, we believe in perfection which makes us laudable and different from others.</p>
              </div>
              </div>
            </div>
            </div>
            </div> 
 </div> */}
           
                 
                
                  <div className='advance_code_div'>
        <div className='advnce_div_cod'>
        
        <h2 data-aos="fade-down">ADVANCED <span style={{color:"#066fb2"}}>CODING SOLUTIONS</span> FOR YOUR PROJECT</h2>
         <p data-aos="fade-right">At <span style={{color:"#066fb2"}}>SEA SENSE</span>, we specialize in providing high-quality software coding services to meet the diverse needs of our clients. With our team of experienced developers and engineers, we are committed to delivering innovative and customized solutions that leverage the latest technologies.</p>
         <div className='advance_btn'>
          <button><Link to="/Contact" style={{textDecoration:"none", color:"#fff"}}  onClick={phd_peoposal}>Click Here</Link></button>
         </div>
       
         </div>
            <div className='advnce_pic' data-aos="fade-left">
            <img src={adva_pic_3} alt=""  />
              

              </div>
      </div>
         
           
  
           <br></br>
           <div className='commite '>
            <div className='oru_commite'>
         
              <h2 data-aos="fade-down">Our Commitment to Excellence</h2>
            </div>
            <div className='commete_div '>
            <div className='commite_main_div' data-aos="fade-right">
              <img src={days} alt="" />
              <h2>365 Days Free Support</h2>
            </div>
            <div className='commite_main_div' data-aos="fade-right">
            <img src={lang} alt="" />
              <h2>No Language Errors</h2>
            </div>
            <div className='commite_main_div' data-aos="fade-left">
            <img src={mba} alt="" />
              <h2>100% Satisfaction Guarantee</h2>
            </div>
            <div className='commite_main_div' data-aos="fade-left">
            <img src={phd} alt="" />
              <h2>MBA/ Msc Dissertation Help</h2>
            </div>
            </div>

 <br></br>
          
            <div className='commete_div_1 ' data-aos="fade-up">
            <div className='commite_main_div_1 '>
            <img src={agre} alt="" />
              <h2>PhD Thesis Writing</h2>
            </div>
            <div className='commite_main_div_1'>
            <img src={asse} alt="" />
              <h2>Non-Disclosure Agreement</h2>
            </div>
            <div className='commite_main_div_1'>
            <img src={asses} alt="" />
              <h2>Comprehensive Assistance</h2>
            </div>
            
           
            </div>
           </div>
           <br></br>
           <br></br>
          
           {/* <div className='pic_main_div'>
  <h2 data-aos="fade-down">How We Work</h2>
  <div className='pic_sib_pic_div' data-aos="fade-down">
    <img src={pic_sib_pic} alt="" />
  </div>
</div> */}



      <div className='fun_fact_baclk'>
            <div className='fun_fact_main'>
              <div className='fun_fat_ma_1'>
              <div className='fun_fact_1'>
                <h2 data-aos="fade-down">COMPANY SUCCESS</h2>
                <h1 data-aos="fade-down">Some  facts about our company</h1>
                <div className='fact_count' data-aos="fade-down">
                  <div className='fact_count_1'>
                      <h1><CountUp end={9} duration={2} />+</h1>
                      <h2>Years of Excellence</h2>
                  </div>
                  <div className='fact_count_1'>
                      <h1> <CountUp end={100} duration={2} />%</h1>
                      <h2>Client Satisfaction</h2>
                  </div>
                  <div className='fact_count_1'>
                      <h1><CountUp end={8300} duration={2} />+</h1>
                      <h2>PhD Thesis Completed</h2>
                  </div>
                  <div className='fact_count_1'>
                      <h1><CountUp end={100} duration={2} />+</h1>
                      <h2>Qualified Experts</h2>
                  </div>
                </div>
              </div>
              </div>
            </div>
            </div>
          
          
          <div className='exper_main_div'>
            <div className='exper_div'>
              <h2 data-aos="fade-right">We have 9+ years experience in PhD Project Development </h2>
              <p data-aos="fade-left">Are you Struggling with your research work? Contact us to get free Research consultancy.</p>
            <div className='exper_num'>
             
              <button data-aos="fade-left" ><Link to="Contact" style={{textDecoration:"none", color:"#fff"}} onClick={phd_peoposal}>Click Here</Link></button>
            </div>

            </div>
          </div>
          
           <div className='fainal_div'>
            <div className='fainal_div_1'>
              <h2 data-aos="fade-down">Let's Chat About Your Academic Goals</h2>
              <p data-aos="fade-up">Want to learn more about how our consultancy services can help you achieve academic success? Book a meeting with our sales consultant today! Our expert team is ready to answer your questions and guide you through the process, so you can make an informed decision about your academic journey.</p>
            </div>
            </div> 
           
           {/* <div className="ocean">
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
</div> */}




<Whatapp/>
<Scrolltop/>
          <Footer/>
        
          </body>
   </>
  )
}

export default Index;

