import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import "../assate/css/research_paper.css";
import re_paper from "../assate/sub_image/ban.jpg";
import pa_ims from "../assate/sub_image/researchpa.jpg";
import per1_pic from "../assate/sub_image/research_paper1.jpg";
import resr from "../assate/sub_image/rese1.png";
import what_logo from "../assate/what/whatsapp_3670051 (1).png";
import Footer from './Footer';
import Scrolltop from './Scrolltop';

const Researchpaper = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='paper_div'>
        <img src={re_paper} alt="" />
        <div className='content-overlay '>
            <h1>RESEARCH PAPER</h1>
        </div>
    </div>
    <div className='research_paper_main_div'>
    <div className='paper_main_div'>
    <h2 data-aos="fade-down">RESEARCH PAPER WRITING</h2>
        
        
            <p data-aos="fade-down">Research paper writing being the decisive factor for a career, it becomes inevitable to get help from someone who has thorough knowledge in the field of research. Research paper writing services at Sea Sense is absolutely handled and written in accordance with the institutional parameters involving style, design, and format of an ideal dissertation. We assist you at all the stages, either its dissertation topic selection or research proposal help for the topic that you have decided to research on or to develop the dissertation content.</p>
            </div>
        <div className='pa_pic' data-aos="fade-right">
            <img src={pa_ims} alt="" />
        </div>
        
    </div>
    <div className='pare_main_div'>
        <h2 data-aos="fade-down">What We Do?</h2>
        <p data-aos="fade-down">Especially, our experts commit themselves to explore their foremost services towards demand of scholars. At the same time, our experts are aware of all hazards of research paper writing which will afford better solution. Experts do astonishing things easily as our writers do. The professional writers in our company can handle any type of projects whatever the subject maybe their quality is always on the top.</p>
    </div>
    <div className='paper_sub_div'>
        <p data-aos="fade-down">Our team of professional journal papers have multiple skills such as technical knowledge, knowledge of various journals, knowledge of journal requirements and guidelines, etc. Apart from that, there are various other reasons why you should choose Seasense Research and Technologies for your journal writing.</p>

        <p data-aos="fade-down">Writing a research paper is considered a daunting task for many PhD students. It mainly involves extensive research and in-depth analysis on the chosen topic. Research writing requires students to have excellent vocabulary and crisp script that should be thought provoking. It is a time consuming process indeed. A candidate must go to great lengths to study a particular topic, to investigate it, and to analyze the data collected. It takes immense patience and perseverance to take appropriate steps to see the project through to the end and complete.</p>

        <p data-aos="fade-down">The research paper writing service offered by Seasense is excellent from several angles. Not only is the content excellent and exhaustive, but the related aspects are in perfect shape too. Recurring problems such as quotations, references and bibliography, plagiarism, already revised topics, language problems, structure are handled skillfully.</p>

        <p data-aos="fade-down">We have a team of writers who specialize in research writing, and each of them has their own specialty. That makes our task even easier as we know exactly who to delegate the work to.</p>
    </div>
    <div className='paer_main_div'>
    <div className='paper_div_pic'>
        <img src={per1_pic} alt="" />
    </div>
    <div className='paer_contant_div'  data-aos="fade-down">
        <h2 data-aos="fade-down">We offer the following guarantees in our service for writing research papers</h2>
        <li>Context-related literature research with a conceptual framework. </li>
       
        <li> Use of current references.</li>
       
        <li> Grammar-free with proofreading.</li>
       
        <li> Scientific / technical writing style.</li>
       
        <li> Thought provoking coherence of paper.</li>
       
        <li> Fast processing time.</li>

      
       </div>
       </div>
       <div className='re_li_v2'>
        <h2 data-aos="fade-down">Methods for writing an effective research paper from our research writing experts</h2>
        <br></br>
            <h2 data-aos="fade-down">Data collection:</h2>
<li data-aos="fade-down">Identifying the topic you want to write should be the primary concern while experts from research paper writing services deal with it. A deep look into the internet, journals, books, or articles will help you take a look at previous studies to gather all the relevant data, graphs, charts, tables, analysis, etc. that will prove to be helpful.</li>

<br></br>
<h2 data-aos="fade-down">Findings:</h2>
<li data-aos="fade-down" >You need to identify two or more important findings from the data you have collected and use them as a central theme in creating the first draft of a research paper. However, our experts in research writing in Seasense follow different writing styles to help you get your desired results.</li>
</div>
    

    <div className='papa_re'>
    <div className="paper_structure">
        <div className='pare_strt'>
            <h2 data-aos="fade-down">Research Paper Structure</h2>
                                <p>
                    There is an adequate research writing structure in place followed by our research writing service in Seasense which will assist you in producing high quality reports. It's listed below:</p>

        </div>
        <div className='pare_strt_1'>
            <h2 data-aos="fade-down">Title :</h2>
            <p data-aos="fade-down">Write a title that is short, simple, and attractive. It gives the reader a brief idea of ​​the research you will write in your report.</p>
            <h2 data-aos="fade-down">Abstract :</h2>
            <p data-aos="fade-down">A small summary is presented briefly and effectively. You need to incorporate key insights in a way that readers can see and understand.</p>
            <h2 data-aos="fade-down">Graphics :</h2>
            <p data-aos="fade-down">Our research paper writing service in Seasense will show you the importance of including an illustration or diagram to educate readers about the subject.</p>
            <h2 data-aos="fade-down">Introduction :</h2>
            <p data-aos="fade-down"> You need to be there while you write your introduction. You need to start a specific section by keeping a specific background on the topic in mind.</p>
            <h2 data-aos="fade-down">Experimental Section :</h2>
            <p data-aos="fade-down">Our experts helping with research writing tend to divide the mentioned section into methods, measurements, data analysis and characterizations.</p>
            </div>
            
           
      
        </div>
        <div className='re_pi_li'>
            <img src={resr} alt="" />
        </div>
    </div>


    <div className='fin_rech'>
    <h2 data-aos="fade-down">Results and Discussion : </h2>
            <p data-aos="fade-down">You need to express the results in a detailed form. The figures / graphics should be attached in a suitable manner alongside the relevant discussion. Using data helps to present your data better.</p>
            <h2 data-aos="fade-down">Conclusions :</h2>
            <p data-aos="fade-down">Our experts, who deal with the provision of research writing services, propose to conclude the discussion about the future perspectives of the topic and its benefits for other industries / topics.</p>
            <h2 data-aos="fade-down">References :</h2>
            <p data-aos="fade-down"> It is important to include the references in your research. If you've posted the same data without telling you where you got the data from, there is a risk of plagiarism. However, make sure that these references are provided in an appropriate format. If you are having trouble citing these testimonials, you can opt to do research writing in Seasense.</p>
    </div>
    <div className='whatsapp_float' >
    <Link to="https://wa.me/7402616151">
        <img src={what_logo} alt="" style={{width:"50px"}} className='whatsapp_float_btn'></img>
    </Link>
</div>
<Scrolltop/>
    <Footer/>
    </body>
    </>
  )
}

export default Researchpaper;