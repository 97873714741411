import React from 'react';
import { Link} from 'react-router-dom';
import what_logo from "../assate/what/whatsapp_3670051 (1).png";

const Whatapp = () => {
  return (
   <>
   
   <div className='whatsapp_float' >
    <Link to="https://wa.me/7402616151">
        <img src={what_logo} alt="" style={{width:"50px"}} className='whatsapp_float_btn'></img>
    </Link>
</div>
   </>
  )
}

export default Whatapp