import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../assate/css/contact.css";
import Header from './Header';
import contact_img from "../assate/sub_image/ban.jpg";
import Footer from './Footer';
import sales from "../assate/image/sales.jpg";
import support from "../assate/image/support.jpg";
import { FaMobileAlt, FaEnvelope } from 'react-icons/fa'; 
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';
const Contact = () => {
    
   
    useEffect(() => {
        const options = {
            threshold: 0.5 
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("animate");
                    observer.unobserve(entry.target); 
                }
            });
        }, options);
        
        const observeElements = (selector) => {
            const elements = document.querySelectorAll(selector);
            elements.forEach(div => {
                observer.observe(div);
            });
        };

        observeElements(".conts");
        observeElements(".contact_div_1_main");
        observeElements(".sale_main_div");
        observeElements(".sale_div_sub");
        observeElements(".support_main_div");
        observeElements(".support_team");
      
      
    }, []); 

  

  return (
    <>
    <body>
       
        <div id="root" style={{overflowX:"hidden"}}>
   <Header/>
   
        <div className='conatct_sub_div'>
            <img src={contact_img} alt=""/>
            <div className="content-overlay">
    <h1>CONTACT US</h1>
   
  
        </div>
    </div>
   

    <div className='conts' data-aos="fade-down">
        <h2>Don’t be Shy! Say Hello!</h2>
    </div>
    <div className='contact_div_1_main'>
        <div className='conatct_div_2' data-aos="fade-down">
            
          
                <h2>Sea Sense Softwares</h2>
                <p>JJ Arcade, Second Floor, Near New Bus Stand</p>
                <p>Marthandam - Kanyakumari District - TamilNadu -629 165, INDIA</p>
                <p>Mob : +91-7402616151</p>
                <p>Phone : 04651-271057</p>
           </div>
            <div className='conatct_div_2' data-aos="fade-down">
                <div className='contact_allapy' >
                    <h2>Sea Sense Technologies</h2>
                    <p>Third Floor, Pulimootil Trade Center</p>
                    <p>Alleppey - Kerala -688 001, INDIA</p>
                    <p>Mob : +91-7402616156</p>
                </div>
            </div>
        </div>
       <div className='sale_main_div'>
        <div className='sale_div_1' data-aos="fade-down">
            <h2>Sales Team</h2>
            <p>How Can We Help?</p>
        </div>
        </div>
            <div className='sale_div_sub'>
                <div className='sale_team_div' data-aos="fade-down">
                    <h2>Sales Team</h2>
                    <p>phone Number</p>
                    <p>  <FaMobileAlt style={{ marginRight: '5px' }} /> <Link to="tel:1800- 5 726 426" style={{textDecoration:"none"}}>+1800- 5 726 426 </Link>(For All Services)</p>
                    <p>  <FaMobileAlt style={{ marginRight: '5px' }} /> <Link to="tel:+91-7402616152" style={{textDecoration:"none"}}>+91-7402616152 </Link>(For All Services)</p>
                    <p> <FaMobileAlt style={{ marginRight: '5px' }} /> <Link to="tel:+91-7402616156" style={{textDecoration:"none"}}>+91-7402616156 </Link>(For All Services)</p>
                    <h2>Email</h2>
                    <p> <FaEnvelope style={{ marginRight: '5px' }} /><Link to="mailto:info@seasensesoftwares.com" style={{textDecoration:"none"}}>info@seasensesoftwares.com</Link></p>
                </div>
                <div className='sale_din_pic' data-aos="fade-down">
                    <img src={sales} alt=""/>
                </div>
            </div>
            <div className='support_team' data-aos="fade-down">
                <h2>Support Team</h2>
            </div>
            <div className='support_main_div'>
                <div className='support_div_1' data-aos="fade-down">

                    <img src={support} alt=""/>      
                    </div>
                    <div className='support_sub_div' data-aos="fade-down">
                        <h2>Support Center</h2>
                        <p>Phone Number</p>
                        <p>
        <FaMobileAlt style={{ marginRight: '5px' }} /> 
        <Link to="tel:1800 5726 426" style={{ textDecoration: "none" }}>1800 5726 426 </Link>(10 AM - 6 PM : IST)
            </p>
                        <p> <FaMobileAlt style={{ marginRight: '5px' }} /> <Link to="tel:04651 - 271057" style={{textDecoration:"none"}}>04651 - 271057 </Link>(10 AM - 6 PM : IST)</p>
                        <h2>Email</h2>
                        <p> <FaEnvelope style={{ marginRight: '5px' }} /><Link to="emailto:projectdelivery@seasensesoftwares.com" style={{textDecoration:"none"}}>projectdelivery@seasensesoftwares.com</Link></p>
                    </div>

            </div>
       
<Whatapp/>
<Scrolltop/>
<Footer/>
</div>
       </body>
    </>
  )
}

export default Contact;