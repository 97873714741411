import React from 'react'
import Header from './Header';
import "../assate/css/java.css"
import java_pic from "../assate/sub_image/bann.jpg";
import jav_pic from '../assate/new_image/java2.png';
import java_pic_2 from "../assate/new_image/java1.png"
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Java = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='java_main_div'>
        <img src={java_pic} alt=""/>
        <div className='content-overlay'>
            <h1>JAVA DEVELOPMENT</h1>
        </div>
    </div>
    <div className='java_dig_div'>
    <div className='java_div_main'>
        <h2 data-aos="fade-down">JAVA DEVELOPMENT</h2>
        <p data-aos="fade-down">SEA SENSE Java architects and developers draw on a well-crafted set of patterns. For this reason, we implement variety of integration solutions in Java development. We are using high futuristic frameworks. Such as, J2EE, JSF, Struts, Spark, Hadoop, etc., We offer the development of high-quality software. On the positive side, this will help to ameliorate the workflow of the company. Particularly, our expertise also allows us to build scalable enterprise software ensuring high performance.</p>
        <h2 data-aos="fade-down" style={{fontSize:"20px"}}>What is Java?</h2>
        <p data-aos="fade-down">Java is one of the most popular programming languages widely used for creating web and mobile applications. As well as, web portals, customized and enterprise software, games, etc. To point out, the immense advantage of Java is its platform independent. It means that the programs written in Java can efficiently run across various system. Particularly, Java development supports system-level design, simulation, automatic code generation.</p>
       
    </div>
    <div className='java_gid_pic' data-aos="zoom-in">
        <img src={jav_pic} alt="" />
    </div>
    </div>

    <div className='java_div_lin_1'>
    <p data-aos="fade-down">PhD Projects in Java are the source of frequent growth and progress for the PhD fresher. This is the most generally used method for developing your project using the topmost coding language. Earlier, we have done so many works using Java project code.</p>
        <p data-aos="fade-down">“Java is The Best Platform for Pupil from Any Stream of Study.” As a result, we give you the best of the best real-time and non-real-time projects

        PhD projects in Java are our evergreen service for you. In fact, most of the PhD pupils connect with us to harvest their success. In that, we offer our GOOD SIGNATURE in all of the aspects.</p>
    </div>
    <div className='java_back_div'>
        <h2 data-aos="fade-down">Backing Ide And Tools For Java Development</h2>
        <div className='java_cont_div'>
            <ul data-aos="fade-down">
                <li>Netbeans</li>
                <li>Eclipse</li>
                <li>Visual Studio</li>
                <li>Android Studio</li>
                <li>Hadoop</li>
            </ul>
            <ul data-aos="fade-down">
                <li>Pycharm</li>
                <li>Raspberry pi</li>
                <li>Selenium</li>
                <li>Apache Maven</li>
                <li>Apache JMeter</li>
            </ul>
            <ul data-aos="fade-down">
                <li>Spark</li>
                <li>IntelliJ IDEA</li>
                <li>Git</li>
                <li>Spring MVC</li>
            </ul>
        </div>
      
    </div>
    <div className='java_supoort'>
       
        <div className='java_supoort_1'>
            <h2 data-aos="fade-down">Cloud Computing:</h2>
            <ul data-aos="fade-down">
                <li>Mobile-Edge</li>
                <li>Fog-Edge</li>
                <li>Mobile-Cloud</li>
                <li>Green Computing</li>
                <li>And also Cloud-Edge</li>
            </ul>
            <h2 data-aos="fade-down">Embedded System:</h2>
            <ul data-aos="fade-down">
                <li>Robotics and also in Artificial Intelligence</li>
                <li>Brain Computer Interface</li>
                <li>Sensor Networks</li>
                <li>Computer Vision</li>
            </ul>
            <h2 data-aos="fade-down">Digital Image Processing:</h2>
            <ul data-aos="fade-down">
                <li>Augmented and also Virtual Reality</li>
                <li>Computer Graphics</li>
            </ul>
            <h2 data-aos="fade-down">Data Mining:</h2>
            <ul data-aos="fade-down">
                <li>Text and Opinion Abstraction</li>
                <li>Image Processing</li>
                <li>Pattern Recognition</li>
                <li>Semantic Web and also in Ontologies</li>
            </ul>
            <h2 data-aos="fade-down">Internet of Things:</h2>
            <ul data-aos="fade-down">
                <li>Green IoT</li>
                <li>Internet of Everything</li>
                <li>And also Internet of Vehicles</li>
            </ul>
        </div>
        <div className='ja_pic' data-aos="zoom-out">
            <img src={java_pic_2} alt=""/>
        </div>
    </div>
    <div className='java_spoty'>
        <p data-aos="fade-down">Know it all: Our programmers are well-versed in the tool and can assist engineering academics with PhD java implementation. For the professionals, the tool is fundamentally modern, and they have been trained in its use.</p>
        <p data-aos="fade-down">Implementation process: We look into your research objective and your base paper to get a clear picture of your PhD research. The team worked on numerous Java developments for PhD projects to implement the study.</p>
    </div>
    <Whatapp/>
    <Scrolltop/>
    <Footer/>
    </body>
    </>
  )
}

export default Java