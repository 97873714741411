import React from 'react';
import "../assate/css/proof.css";
import proof_pic_1 from "../assate/new_image/proofreading.jpg"
import proof_2 from "../assate/new_image/proofre.jpeg";
import proof_pic from "../assate/newwebpic/banner4.jpg";
import Header from './Header';
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Proof = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='proof_din_oic'>
        <img src={proof_pic} alt="" />
        <div className='content-overlay'>
            <h1>PROOF READING </h1>
        </div>
    </div>

    <div className='pro_div_li_new'>
    <h2 data-aos="fade-down" style={{color:"#066fb2"}}>PROOF READING</h2>
        <p data-aos="fade-down">Proof reading is an additional service which is provided to our Scholars. In fact, this is taken as a separate service for content provided by scholar also. However, this feature includes scrutiny of the subject. In that case, whether there is a need to paraphrase the original content. In case, if so the meaning of idea must not change as well.</p> 
    </div>
    <div className='proof_main_div'>
    <div className='proof_main_div_1'>

        <p data-aos="fade-down">Certainly, if necessary, content alteration and management is done to reach audience in a proper way. In order to, the technical and non-technical aspects of the content. As well as, verified so that the meaning and intention of the scholar is taken care totally. This is effectively carried out by our team in which we have specialists. Certainly, content modifiers who provide support in content related services. Especially, our Plagiarism Check service helps to identify passages. In order to, your manuscript that might be flagged by the journal for accidental plagiarism. In fact, our technical writers will give you a proposal with standard. For this reason, advanced vocabulary to make your proposal richer.</p>
    </div>
    <div className='proof_pic' data-aos="fade-right">
        <img src={proof_pic_1} alt="" />
        
    </div>
    </div>
    <div className='proff_div'>
        <div className='proof_pic_2' data-aos="fade-down">
            <img src={proof_2} alt="" />
        </div>
    <div className='proof_sub_content_div'>
        <p data-aos="fade-down">The most important aspect of completing your Ph.D. writing a thesis. After collecting and analyzing data, it is not always easy to present the information clearly and accessible manner. The way you report your argument can make or break it. Your writing should convey your thoughts to the reader in a clear, logical, and descriptive manner. The whole topic should flow logically. It can be difficult to make a smooth transition from one chapter to the next.</p>
        <p data-aos="fade-down">After months of work, you may have occupied yourself so much with the topic of your thesis that you have not found any errors. Here we offer our help by objectively examining your thesis. We ensure that your thesis is free of grammatical errors, long sentences and bombastic terms, among other things. We help you to convince examiners and readers of the quality of your research.</p>
        <p data-aos="fade-down">We offer various editing and proofreading services for dissertations, theses, research papers and other academic papers. When writing and proofreading, we rely on absolute clarity. Our proof readers and editors go through the entire manuscript, identify any errors, and then make a final copy. Proofreading involves carefully reviewing the content, identifying problems, and correcting typographical errors in language, spelling, and style.</p>
        <p data-aos="fade-down">We make certain that your thesis material presents your thoughts clearly, logically and descriptive manner. We provide a logical flow throughout the subject. Our expert touch will assist you in persuading the examiners and readers of the quality of your research. For an extra cost, Seasense will prepare your thesis according to the criteria of your university.</p>
    </div>
    </div>
    <div className='proof_main_card_div'>
        <div className='proof_sub_card' data-aos="fade-down">
            <h2>What Are the Benefits of Using Seasense Proofreading Services?</h2>
            <ul>
                <li>You could be an expert in your field. Nonetheless, an expert is required to bring your ideas and information together in a cohesive form that is recognized and acknowledged by the academic body.</li>
                <li>Since you know what you meant, the more likely we will recognize mistakes you made by mistake.</li>
                <li>As editors, we can be more objective than the author.</li>
                <li>After months of living and breathing the subject, writers are often too connected to be critical. However, this is not the case with the editorial team</li>
                <li>Getting a second opinion from someone who knows what the academic community accepts can be very useful.</li>
                <li>When you are nearing the end of your PhD and are exhausted, you need the extra support from someone who can critically read and criticize your dissertation.</li>
            </ul>
        </div>
        <div className='proof_sub_card' data-aos="fade-down">
            <h2>Our Editing process involves</h2>
            <ul>
                <li>Consultation with you to ensure that you understand the thesis you are writing as well as the thesis writing criteria offered by your university.</li>
                <li>Correcting all grammatical faults, such as subject-verb agreements, conjugate use, verbs and nouns, and so on. We make certain that there are no frequent errors that could change the content's overall meaning.</li>
                <li>Correcting structural errors such as excessively long sentences, ensuring that there are no fragmented sentences or syntax faults, identifying incomprehensible sentences, and so on.</li>
                <li>Check to see if the thesis and its presentation follow your university's norms and requirements.
</li>
                <li>Proofreading to ensure that all errors and mistakes have been eliminated to a minimum</li>
            </ul>
            </div>
    </div>
    <div className='proof_final_div'>
        <p data-aos="fade-down">Seasense Softwares offers professional proofreading and editing services to ensure that your thesis meets all the rules and requirements of your university and is free of grammatical and presentation style errors.</p>
        <p data-aos="fade-down">We also give our consumers the option of picking and choosing the services they require. At Seasense Software strongly believe that the proof-reading and editing of your thesis should be totally in our expert hands.</p>
    </div>
    <Whatapp/>
    <Scrolltop/>
    <Footer/>
    </body>
    </>
  )
}

export default Proof