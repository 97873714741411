import React from 'react';
import "../assate/css/vlsi.css";
import vlsi_pic from "../assate/new_image/vlsi.png";
import vlsi_design from "../assate/new_image/vlsi_design.jpg";
import Header from './Header';
import back_pic from "../assate/sub_image/bann.jpg";
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Vlsi = () => {
  return (
   <>
   <body>
   <Header/>
   <div className='vlsi_back_pic'>
    <img src={back_pic} alt="" />
    <div className='content-overlay'>
        <h1>VLSI DEVELOPMENT</h1>
    </div>
   </div>
    <div className='vlsi_main_div'>
        <div className='vlsi_sub_div' data-aos="fade-down">
            <img src={vlsi_pic} alt=""/> 
        </div>
        <div className='vlsi_cont'>
            <h2 data-aos="fade-down" style={{color:"#066fb2"}}>VLSI DEVELOPMENT</h2>
            <p data-aos="fade-down"> Sea Sense provides VLSI development services for both the manufacturers who design the products as well as scholars who are doing researches in this field. Here we will derive programs for designing the chips using various programming languages. Also, we will assist the PHD scholars for doing their researches in this field by implementing the programs and analyse how it will embed with the software. Our team of specialized developers who are well expert in deriving code for designing the chips helps the customers to deliver the products.</p>
            <p  data-aos="fade-down">However, we have the potential to develop high-end complex chip designs from concept to production-ready wafer or chip. At the same time, we assimilate changing customer demands and develop innovative future-enabled solutions accordingly. Moreover, our engineers have varying levels of hands-on experience in domain knowledge, latest technologies, design methodologies, modelling languages and verification techniques used in the industry.</p>
        </div>
    </div>
    <div className='vlsi_sub_cont_div'>
<p data-aos="fade-down">You are looking for the best VLSI project assistance in this scenario. But it is not that easy to get the world-class assignment help in VLSI. The majority of tutors and helpers simply cover the fundamentals in their services, and thus are unable to solve the complex programming error. We have the most experienced specialists at Seasense Software who can support you in your VLSI projects. The important problems of the VLSI project are solved by our specialists. So what are your thoughts? Get the best VLSI project offers from the best specialists and realize your ambitions to get high grades.</p>

    </div>
<div className='vlsi_design'>
    <div className='vlsi_design_1'>
        <h2 data-aos="fade-down" style={{color:"#066fb2"}}>VLSI projects</h2>
        <ul data-aos="fade-down">
            <li>High Speed Data Transmission.</li>
            <li>Secure Video Processing.</li>
            <li>Secure Digital Demodulation.</li>
            <li>Signal Processing and Applications.</li>
            <li>Intelligent Robotics.
</li>
        </ul>
        <p data-aos="fade-down">Our research interests cover low power processor architectures, low power circuit design techniques, analog and mixed signal circuit design, rapid prototyping of digital systems, reconfigurable processors, Digital arithmetic, advanced processor architectures, VLSI implementation of signal and image processing algorithms, testing verification, memory design, Embedded VLSI and asynchronous circuits.</p>
    </div>
    <div className='vlsi_design_pic' data-aos="fade-down">
        <img src={vlsi_design} alt="" />
    </div>
</div>
<div className='vlsi_final_div'>
    <p data-aos="fade-down">If we narrow down our discussion to research in areas like electronics, electrical, computer science, artificial intelligence, wireless communication and related fields, which are the base of everything in this high-tech world.</p>
    <p data-aos="fade-down">Since the research fields we are discussing provide a foundation for the emerging world and provide it with reliable technologies that can be used in real time, the work of researchers expands from concept to realization in the actual world in the form of an application or product.</p>
</div>
<Whatapp/>
<Scrolltop/>
<Footer/>
</body>
   </>
  )
}

export default Vlsi