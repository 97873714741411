import React, { useEffect } from 'react';
import Header from './Header';
import "../assate/css/about.css";
import about_img from "../assate/sub_image/banner1.jpg";

import { FaStar } from 'react-icons/fa';
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const About = () => {

    useEffect(() => {
        const options = {
            threshold: 0.5 
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("animate");
                    observer.unobserve(entry.target); 
                }
            });
        }, options);
        
        const observeElements = (selector) => {
            const elements = document.querySelectorAll(selector);
            elements.forEach(div => {
                observer.observe(div);
            });
        };

        observeElements(".Ab");
        observeElements(".wee_are");
        observeElements(".confidentiality");
        observeElements(".vision");
        observeElements(".three_card_div");
        observeElements(".core_value");
      
      
    }, []); 
  return (
    <>
    <body>
    <Header/>
    <div className='about_main_div'>
        <div className='about_main'>
            <img src={about_img} alt="" />
            <div className="content-overlay">
    <h1>About us</h1>
   
  </div>
        </div>
    </div>
   
 
    <div className='wee_are'>
        {/* <div className='wee_main_div'>
            <img src={abs} alt="" />
            
        </div> */}
        <div className='weee_sub_div' data-aos="fade-right">
            <h2>Who We Are</h2>
            <p>Sea Sense has grown as market leaders in providing technical, non-technical, writing and document correcting services to Research scholars all over the world for the last 9 years. We stand unique in delivering exclusive services for Research scholars by having the only objective to delight the customer by providing more to Research scholars. Also provide inclusive services by providing all kinds of writing Services. We own unique culture values attitudes and ethical perspective which makes the ideal choice of Research scholars all over the world. We derive our strength from technical team, writing team and business management team who are willing to address scholar requirements. We have exclusive roots on service excellence, delivery on time, understanding client needs, providing technical demonstrations and support services.</p>
        </div>
    </div>
  
   <div className='confidentiality' >
    <div className='confitend'data-aos="fade-left">
        <h2>Confidentiality and Privacy</h2>
        <p>We ensure that your research data, like your concept, data, printed copies, soft copies, research articles and every document related to your research is confidential and strictly handled only for the internal purposes. We get Non-Disclosure Agreement signed from our expertise who is going to work on your project so that you can be free from hassles in the future. Your data will be kept with us up to six months so that in case if you accidently lost your data you can get in touch with us for the secured research work of yours. We never use your research works in future from our company or our professional writers or expertise since the copyright of the work is totally for yours because payment dues clearance.</p>
        <p>SEA SENSE lays equal emphasis on the core values of dedication and a united effort towards achieving the goals regardless of whether those goals are set on a long term or short term basis. We believe in emerging as a knowledge based enterprise with an emphasis on making our ultimate vision a reality.</p>
    </div>
   
   </div>
   <div className='vision'>
    <div className='vis_di'>
    <div className='vision_main_div' data-aos="fade-right">
        <h2>VISION</h2>
        <p>Sea Sense aspires to the best research guidance provider in the world through its holistic approach irrespective of subjects, countries, and specializations.</p>
        </div>
        <div className='mision'data-aos="fade-left">
            <h2>MISSION</h2>
            <p>To achieve this vision, we approach each research through unique methodology and after a lot of discussion between research experts, professors, data management experts, industry professionals and language & technical editors.</p>
        </div>
        </div>
   
   </div>
   <br></br>
   <div className='three_card_div'>
    <div className='three_main_div' data-aos="fade-right">
        <h2>FACTS ABOUT US</h2>
       
           <h3><FaStar /> Own Cloud Datacenter</h3>
           <h3><FaStar /> Single Control Panel for all services.</h3>
           <h3><FaStar /> Skilled & Dedicated Team</h3>
           <h3><FaStar /> Cloud Services</h3>
           <h3><FaStar /> We are a root level company.</h3>
           <h3><FaStar /> TollFree Customer support</h3>
        
        </div>
        <div className='three_main_div' data-aos="fade-down">
        <h2>CORE VALUES</h2>
       <p>Our team heads have rich experience in the field of research for more than 15 years especially in the development of questionnaire, research design, quantitative and qualitative study designs, and statistical analysis. The technical heads will guide you through your research journey and guide you to achieve your PhD. There is complete support at every stage from the identification of PhD topic to the final document.</p>
        </div>
        <div className='three_main_div' data-aos="fade-left">
        <h2>MILESTONES</h2>
       
           <h3><FaStar /> 1500+ Research Publications</h3>
           <h3><FaStar /> 2500+ Happy Scholars</h3>
           <h3> <FaStar /> E-mail alerts for scholars</h3>
           <h3><FaStar /> Own SMS Application</h3>
           <h3> <FaStar /> 12000+ Happy Customers</h3>
           <h3><FaStar />  World wide approach.</h3>
        
        </div>
   </div>
   <div className='core_value'>
    <div className='core_main_div'>
        <h2 data-aos="fade-down">Commitment:</h2>
        <p data-aos="fade-up"> SEA SENSE strongly believes in the principles of commitment as it builds a bridge for long term Relations.</p>
        <h2 data-aos="fade-down">Excellence:</h2>
        <p data-aos="fade-up"> It is enormously crucial to be ambitious for the growth. So, we believe in perfection which makes us laudable and different from others.</p>
        <h2 data-aos="fade-down">Diligence:</h2>
        <p data-aos="fade-up"> We have a reputation of being enthusiastic as we appreciate that no mission could be reached without dedication.</p>
        </div>
   </div>
   <Whatapp/>
   <Scrolltop/>
   <Footer/>
   </body>
   </>
  )
}

export default About;