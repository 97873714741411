import React from 'react'
import Header from './Header';
import "../assate/css/topic.css";
import topic from "../assate/sub_image/ban.jpg";
import select_pic from "../assate/image/topic.jpg";
import top_pic_1 from "../assate/new_image/topic-flow.png";
import Footer from './Footer';
import li_to_pic from "../assate/sub_image/topic.jpg"
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Phdtopicselect = () => {
  return (
   <>
   <body>
   <Header/>
   <div className='topic_mian_div'>
   <img src={topic} alt=""/>
   <div className='content-overlay'>
    <h1>PHD TOPIC SELECTION</h1>
   </div>
   </div>
   <div className='topic_deleted_div'>
   <div className='topic_pic' data-aos="fade-left">
            <img src={select_pic} alt=""/>
        </div>
    <div className='topic_slt'>
        <h2 data-aos="fade-down">Phd Topic Selection</h2>
        
        <p data-aos="fade-down">Most of the scholars is facing an extremely hectic and frustrating when selecting the best and unique PhD Topic for their dissertation that has Well-Researched and has a very clear research goal. Undoubtedly, topic selection need of an extensive literature search by referencing many peer reviewed and online sources. Our technical specialists approach the scholar to get some informative details regarding their research interests. To begin with, we try to understand your domain interest in the researching field. In fact, after determining your domain interest, we will surf and explore the classical works through various internet sources. Hence, from this investigation we will conclude some topics as most recent as well as, hot topics in that specific research arena.</p>
       
        </div>
       
       
  
    </div>
   
   <div className='tpc' data-aos="fade-down">
            <p>The utmost priority towards selecting the topic should be based on candidates;</p>
            <p>- Passion</p>
            <p>- Interest</p>
            <p>- Knowledge</p>
        </div>
        <div className='topic_sub_content_div'>
            <p data-aos="fade-down">The choice of a PhD topic is very enthusiastic. But hesitated which topic to choose. There are several ways for selecting a PhD research topic. One of the best ways is to seek help from an advisor, who can be the supervisor, guide, or institute concerned. Seasense has a qualified research professional team with more experience in topic selection. The researcher can select the topic from a list provided by the supervisor/ advisor with the desired dissertation. Their experience in topic selection will guide you focus on the right topic. When a topic selection, think about the topics that arouse curiosity and turn them into various questions. Creating a list of these questions could help you come up with a topic.</p>
            
            <p data-aos="fade-down">The writers at Seasense are specialists at selecting the right topic and title for a thesis based on their expertise and personal interests. Furthermore, our writers have the abilities and knowledge to choose a PhD research topic that is appropriate for your research.</p>
        </div>
       
       
           <br></br>
           <div className='to_lo_pi'>
            <div className='tpoic_selecte_sub_div'>
           
            <div className='tpic_seleted_content_div' data-aos="fade-down">
                <h2 data-aos="fade-down">Choosing good topics for a PhD research project can do wonders for your PhD research. Wonder how?</h2>
                
              <li>Select a PhD topic that suits both your interests and the topic you are pursuing, and keep yourself motivated and your research skills intact during this busy journey!</li>
               
                    <li>  Without some understanding of the topic of the thesis, your PhD can become a piece of aimless, floating ideas, and unguided.</li>
                  
                    <li> Find quality research topic ideas</li>
                   
                    <li>  Evaluate topic ideas systematically</li>
                   
                    <li>   Choose a successful research topic</li>
                
            </div>
        
        <div className='phd_tpic_slt'data-aos="fade-down">
            <h2 data-aos="fade-down">So what should a good PhD Topic look like?</h2>
            <li>The chosen topic must explain the motive of your studies. </li>
           
            <li> It must relate both to the study objectives and to the central question.</li>
           
            <li> It has to be specific and give you enough scope to research.</li>
           
            <li>It needs to be clear, legible and self-explanatory to attract the reader's interest. </li>
        </div>
        </div>
        <div className='to_pic_lo_vi'>
            <img src={li_to_pic} alt="" />
        </div>
        </div>
       <br></br>
        <div className='tpoic_div_cnt_div' data-aos="fade-down">
            <h2 data-aos="fade-down">In finding a topic and formulating the thesis statement, we make the following easier for you</h2>
            <li>Brainstorm your ideas </li>
           
            <li>Understand the research process. </li>
           
            <li> Understand what your university wants.</li>
           
            <li> Identify your broad interests.</li>
           
            <li>Identify specific potential topics. </li>
           
            <li>Systematically evaluate each topics. </li>
           
            <li> Topics that have been well-researched and are potentially interesting.</li>
           
            <li>A topic list is provided from which any topic can be chosen. </li>
           
            <li>Individual selection of topics based on the suggestions of your supervisor / guide. </li>
           
        </div>

     
      
         <div className='tyop_pic'>
         <div className='top_div' data-aos="fade-right">
            <img src={top_pic_1} alt="" />
            </div>
        <div className='topic_final_div' data-aos="fade-down">
            <h2 data-aos="fade-down">How can we at Seasense help you to choose a good topic for your dissertation?</h2>
            <li> In Seasense, our experts offer assistance in the selection of PhD topics so that your dissertation topic is unique and innovative, suitable for the subject you have chosen and, above all, arouses the interest of researchers.</li>
           
            <li> We have access to several research databases that help us review extensive literature. This advantage leads to a credible selection service for dissertations by our experts.</li>
           
            <li> Dissertation topics will be chosen based on the research gap as well as future research recommendations from previous researchers. As we understand, this title should reflect the problem, questions, research area of the study, results, study design, ethnicity, and location of the study.</li>
           
            <li> The goal and study objectives were proposed, which will be established based on a thorough literature review and the identification of the problem.</li>
           
            <li> Because the literature review is so important in your dissertation, we've included the most recent articles published in the proposed field, as well as the problem and proposed methodology, which includes qualitative or quantitative research design, data collection methods, target population, tools used, and the expected outcome.</li>
        
        </div>
        </div>
        <Whatapp/>
        <Scrolltop/>
        <Footer/>
        </body>
   </>
  )
}

export default Phdtopicselect