import React from 'react'
import Header from './Header';
import "../assate/css/proposal.css";
import pros from "../assate/sub_image/ban.jpg";
import proposal_imgs from "../assate/sub_image/proposal.jpg";
import pro_picsss from "../assate/sub_image/proposal1.png";
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Phdproposal = () => {
  return (
   <>
   <body>
   <Header/>
   <div className='propsal_main_div'>
    <img src={pros} alt="" />
    <div className='content-overlay '>
        <h1>PHD PROPOSAL</h1>
    </div>
   </div>
   <div className='proposal_div'>
    <div className='proposal_div_1'>
        <h2 data-aos="fade-down">PhD Proposal</h2>
        <p data-aos="fade-down">Writing a research proposal is an important part of Ph.D. degree as it is an integral part of the Ph.D. application process. Basically, in the sense of the dissertation process, PhD proposal is the most demanding part that cannot be underestimated</p>
       
        <p data-aos="fade-down">At the same time, are you apt to write and submit that all significant PhD dissertation proposal? If so, you will recognize correctly how hectic that can be. In fact, you will be familiar with how much of your future goes on receiving this one paper right. As well as, if you hope that writing your paper for your master’s degree was tough. Finally, the PhD proposal is a different level.</p>
        <li data-aos="fade-down">We can formulate, define and suggest a clear research topics or question, thereby highlighting its uniqueness and its implication. </li>
       
        <li data-aos="fade-down">A clear, practical methodology will be outlined to you so that you are capable of answer, evaluate and describe these data sources or research questions. </li>
       
        <li data-aos="fade-down"> Suggestions will be given to you regarding the new areas that will excavate.</li>
    </div>
    <div className='proposal_pic'data-aos="flip-left">
        <img src={proposal_imgs} alt="" />
    </div>
   </div>
   <div className='pro_div_main'>
    <div className='pro_sub_div_1' data-aos="flip-right">
        <img src={pro_picsss} alt="" />
    </div>
    <div className='pro_div_content'data-aos="fade-down">
        <h2 data-aos="fade-down">Why an expert proposal writing service?</h2>
        <li >  Writing a thesis proposal is a huge task that requires careful attention to detail.</li>
       
        <li > Acceptance of a thesis proposal is governed by strict procedures at universities.</li>
       
        <li >It gives an initial valuation of the thesis's general level of writing, topic, and relevancy. </li>
       
        <li > Proposal for Research studies are the most common goal for people who want to become in the academic area. Which begin with the most promising research suggestions. This professional Research Proposal will be established, especially if you are pursuing a PhD.</li>
        <div className='pro_div_content_1' data-aos="fade-down"   >
    <h2>Why choose us for writing a proposal for PhD?</h2>
    <li>On-time delivery. </li>
   
    <li> A thesis proposal that is of high quality, well-written, and well-edited.</li>
   
    <li> Linguistically and grammatically perfect material.</li>
   
    <li> Detailed information on the thesis project.</li>
   
    <li> Access to a pool of professional thesis proposal authors.</li>
   
    <li> With Sea sense’s Research Proposal, you won't have to worry because we'll give you with all of the minute facts and other requirements of your projects with the utmost accuracy and benefits. Everything we send you is 100% original; nothing is plagiarized, and every document and paper is maintained in strict confidence.</li>
   
    <li>We also help the researcher identify how his proposed research idea can overcome the limitations in the existing work and highlight the proposed idea's significance and application. </li>
   
   </div>
        </div>
        </div>
       <br></br>
       <br></br>
      
   
    
  
   <div className='proposal_final' data-aos="fade-down">
    <h2 data-aos="fade-down">How are we different?</h2>
    <li>Our qualified and experienced PhD thesis proposal writers in Seasense keep up with different universities various formalities and guidelines. </li>
   
    <li>Our PhD thesis synopsis writer ensures that the documentation, structure, and format of the thesis proposal are in line with the evaluators' expectations.</li>
   
    <li> We have been a pioneer in contributing to several new algorithms and the invention of different new ideas by ourselves with a team of highly efficient professionals. We focus on providing you with the best PhD assistance we can give by exploring all the existing methodologies and existing techniques and then making proper notes on them to make them technically stronger.</li>
   
    <p>With strict consideration given to your university guidelines, we follow PhD thesis format sample and add to the credibility of your proposal.</p>
    <p>So, Contact us now for a well-researched, error-free, and properly drafted PhD thesis proposal writing services in Seasense!</p>
   </div>
   <Whatapp/>
   <Scrolltop/>
  <Footer/>
  </body>
   </>
  )
}

export default Phdproposal