import React from 'react';
import "../assate/css/scrolltop.css";

const Scrolltop = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0, // Scroll to the top of the page
          behavior: 'smooth' // Smooth scrolling animation
        });
      };
  return (
   <>
   <div className='dd' id="progress" onClick={scrollToTop}>
   <span id="progress_value">
  <i className="fas fa-arrow-up"></i>
</span>
        </div>
   </>
  )
}

export default Scrolltop