import React from 'react';
import "../assate/css/gammer.css";
import grammer_pic_pic from "../assate/new_image/gra.jpg";
import grama_pic from "../assate/new_image/german-language-correction-service.jpeg";
import Header from './Header';
import gram_pic_1 from "../assate/newwebpic/banner4.jpg";
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Grammer = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='gram_pic'>
        <img src={gram_pic_1} alt="" />
        <div className='content-overlay'>
            <h1>GRAMMAR CHECKING</h1>
        </div>
    </div>
    <div className='grammer_main_div'>
        <h2 data-aos="fade-down" style={{color:"#066fb2"}}>GRAMMAR CHECKING</h2>
        <p  data-aos="fade-down">Our specialists will find each sentence in a text look up each word in the dictionary. As well as, effort to evaluate the sentence into a form that matches a grammar checking. Specially, we also assist our scholars with this service for the content provided to us by them:</p>
    </div>
    <div className='grammer_sub_div'>
        <div className='grammer_sub_div_1'>
            <ul data-aos="fade-down">
                <li>Identification and correction of grammar mistakes</li>
                <li>Sentence corrections</li>
                <li>Error in meaning and content</li>
                <li>Paragraphing</li>
                <li>Punctuation and typographical errors checking</li>
                <li>Provision of style in content and in presentation</li>
                <li>Check the flow and meaning of content</li>
            </ul>
        </div>
        <div className='grammer_pic' data-aos="fade-left">
            <img src={grammer_pic_pic} alt="" />
        </div>
    </div>
    <div className='gramer_sub_content_div'>
        <h2 data-aos="fade-down" style={{color:"#066fb2"}}>Grammar check in Your Research Paper</h2>
        <p data-aos="fade-down">
Our experts will look up each word in the dictionary and find each sentence in a document. Also, try to parse the statement into a format that can be checked for grammar.</p>
<p data-aos="fade-down">If you're an author hoping to get your work published, you'll want to make sure it's well-written and free of embarrassing errors. That is exactly what Editage's high-quality English editing services accomplish. Our professional editors are experienced in recognizing and correcting grammatical and linguistic errors. Like any English proofreading service, we not only work on the obvious problems in your words and sentences, but we will also discover and repair the deeper issues in your writing that could potentially dilute its impact.</p>
    </div>
    <div className='gram_fl_div'>
        <div className='gram_pic' data-aos="fade-right">
            <img src={grama_pic} alt="" />
        </div>
    <div className='grammer_final_div'>
        <h2 data-aos="fade-down" style={{color:"#066fb2"}}>Grammar & Spell Checker Benefits You Can Expect</h2>
        <p data-aos="fade-down"> There are many different types of spell checkers. At Seasense, we've made our software unique so that it stands out from the rest.</p>
        <ul data-aos="flip-down">
            <li>It’s credible: We regularly test our software to make sure that it is free of flaws and that it is as accurate as possible.</li>
            <li>Advanced correction: Our checker examines text on a variety of levels, from the surface to the deepest semantic depths.</li>
            <li>Authority: Students are increasingly turning to our product because they are pleased with their findings.</li>
            <li>It improves grammar: Our system is designed to point out errors and suggest strategies for correcting them. You can see exactly what is wrong with a statement and avoid future problems.</li>
            <li>It saves time: Our checker works at lightning fast, so you'll have your results and suggestions for corrections in around ten seconds.</li>
        </ul>
    </div>
    </div>
    <Whatapp/>
    <Scrolltop/>
    <Footer/>
    </body>
    </>
  )
}

export default Grammer