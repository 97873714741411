import React from 'react'
import Header from './Header'
import Footer from './Footer';
import "../assate/css/thesiswriting.css";
import thesis_pic_im from "../assate/sub_image/ban.jpg";
import th_pic from "../assate/newwebpic/thesisw.jpg";
import the_pic_2 from "../assate/new_image/thesis1.png"
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Thesiswriting = () => {
  return (
    <>
    <body>
    <Header/>
    <div className='thesis_main _div'>
        <div className='thesis_pic'>
            <img src={thesis_pic_im} alt=""/>
            <div className='content-overlay'>
                <h1>THESIS WRITING</h1>
            </div>
        </div>
    </div>
    <div className='thes_pic_div'>
   <div className='thesis_div'>
        <h2 data-aos="fade-down">THESIS WRITING</h2>
        <p  data-aos="fade-down">Are you struggling with your PhD thesis writing? Are you a scholar, working full time? Are there any other things that keeps you busy often? In fact, it does not be your concern anymore. Especially, we are providing the professional thesis writing services to our valuable scholars. Our custom thesis writing service consists a team of professional thesis writers of all domain who can very well handle the complexities in academic writing. As well as, we work towards your requirements on how the thesis should be and improve our best thesis writing and editing services in line with your specifications</p>

        <p data-aos="fade-down">Strong thesis statement is the initial step in entire research process. We also provide support along with thesis writing in research papers for scholars. As well as, we cater dissertation editing services wherein we do complete verification of the document in which punctuation, grammar and language content would be verified. Our thesis examples work stand as for knowledge and its unique contributions in the field. Likewise, we provide essay thesis writing domain for research students also.</p>
        </div>
        <div className='thes_main_pic_div' data-aos="flip-left">
            <img src={th_pic} alt="" />
        </div>
        </div>
        <div className='thesis_sub_div'>
        <h2 data-aos="fade-down">Professional Thesis Writers</h2>
        <p data-aos="fade-down">
        Our research team offers full and complete assistance in writing dissertations. It binds the customer with commitment and comprehensive support. At the same time, our research writers also provide thesis statements for clients that make the process of the research work and the way it is carried out clearly understandable.</p>
        <p data-aos="fade-down">A strong thesis statement is the first step in the entire research process. We also offer support services along with the writing of thesis in research papers for scholars. We also offer editing services for dissertations where we fully review the document. Punctuation, grammar and language content are checked. Our working examples for thesis represent knowledge and its unique contributions in this area.</p>
        <p data-aos="fade-down">Seasense takes pride in offering thesis writers a whole range of services. Seasense is the most popular service that caters to the needs of all dissertation writers. The reasons for our service's sympathy are self-evident; our writers are the best and provide prompt assistance with thesis as well as polite counsel.</p>
        <p data-aos="fade-down">Thesis Help is a specialized service that provides guidance to scholars on the many chapters of a dissertation. The writers serve as mentors to the researchers, assisting them with the completion of chapters such as the introduction, literature review, research methods, results and discussion, conclusion, and bibliography. Topic selection and data analysis tools are also discussed. The writers develop a dissertation blueprint from which scholars might create their own unique work. The writers have worked on several thesis on a wide range of topics and can offer experienced advice on how to publish the greatest research in any field.</p>
    </div>
    <div className='thes_gud'>
<div className='thes_gud_1' data-aos="flip-right">
    <img src={the_pic_2} alt="" />
</div>
   
    <div className='thesis-guide_line'>
        <h2 data-aos="fade-down">Guidelines For Thesis Writing</h2>
        <div className='thesis-guide_line_1'>
            <h2 data-aos="fade-down">Formatting Rules:</h2>
            <p data-aos="fade-down">Our PhD thesis writers who give PhD thesis writing services in Seasense are aware with a variety of formatting norms and ensure that font style and size, margins, spacing, and other elements adhere to them. They also double-check the figures, tables, and images, making sure they're in the right position and referencing them in the text.</p>
            <h2 data-aos="fade-down">Language and grammar:</h2>
            <p data-aos="fade-down">Thesis are written in a precise scientific language. The spelling of technical terms is double-checked. Grammar and tense are also in line with academic requirements.</p>
            <h2 data-aos="fade-down">Authenticity:</h2>
            <p data-aos="fade-down">All of the data and numbers utilized in the project must be accurate. Only original ideas from researchers are provided in the dissertation, according to the writers. They double-check the accuracy of all projected assertions and outcomes.</p>
            <h2 data-aos="fade-down">Citation:</h2>
            <p data-aos="fade-down">Our writers do not prepare any plagiarized content. Our team not only ensures that the script is 100% unique, but they also provide proper citations for any text taken from other sources. The citations are in the same style as the editorial.</p>
            <h2 data-aos="fade-down">Punctuality:</h2>
            <p data-aos="fade-down">Because dissertations are lengthy documents, it is critical to keep track of your writing so that you can finish them on time. Both speed and accuracy are important to our writers.</p>
        </div>
    </div>
    </div>
    <div className='thesis_succes'>
        <h2 data-aos="fade-down">Successful Thesis Statement</h2>
        <p>
Seasense Softwares can also assist you with writing for the presentation of your thesis. Your research expertise and confidence will be determined by your thesis presentation, which is equally as crucial as your thesis writing. We effectively advise you on how to create the right presentation and assist you in presenting your thesis in the best possible light. From the beginning to the finish of your dissertation, our services are comprehensive.

</p>
    </div>
    <Whatapp/>
    <Scrolltop/>
    <Footer/>
    </body>
    </>
  )
}

export default Thesiswriting