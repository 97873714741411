import React, { useEffect } from 'react';
import Header from './Header';
import "../assate/css/services.css";
import service from "../assate/sub_image/Untitled design (16).png";
import serimg from "../assate/image/se.png";
import jndc from "../assate/icon/topic.png";
import proposal from "../assate/icon/offer_12003812.png";
import paper from "../assate/icon/exam_5012253.png";
import journal from "../assate/icon/notepad_9305648.png";
import synopsis from "../assate/icon/creation_1999089.png";
import thesis from "../assate/icon/content-writing_9481607.png";
import next from "../assate/icon/arrow-right_2040519.png";
import matlab from "../assate/icon/mat.jpg";
import java from "../assate/icon/java.jpg";
import python from "../assate/icon/python1.jpg";
import vlsi from "../assate/icon/vlsi.jpg";
import edit from "../assate/icon/sideedit.jpg";
import nd from "../assate/icon/2dm.jpg";
import sil from "../assate/icon/simu.jpg";
import hffss from "../assate/icon/hs.jpg";
import pla from "../assate/icon/blackcheck.jpg";
import gram from "../assate/icon/cheking.jpg";
import proof from "../assate/icon/poof.jpg";
import litere from "../assate/icon/lit.jpg"
import langu from "../assate/icon/language.jpg"
import Footer from './Footer';

const Services = () => {

    useEffect(() => {
        const options = {
            threshold: 0.5 
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("animate");
                    observer.unobserve(entry.target); 
                }
            });
        }, options);
        
        const observeElements = (selector) => {
            const elements = document.querySelectorAll(selector);
            elements.forEach(div => {
                observer.observe(div);
            });
        };

        observeElements(".service_sub_cont");
        observeElements(".ser_pic");
        observeElements(".sub_div_card");
        observeElements(".develpom_div");
        observeElements(".add_no_main_div");
        observeElements(".application_main_div");
      
      
    }, []); 
  return (
   <>
   <Header/>
   <div className='services_main_div'>
    <div className='services_pic'>
        <img src={service} alt=""/>
    </div>
   </div>
   <div className='service_cont'>
    <div className='service_sub_cont'>
        <h2>Professional advice on methodological selection, study design, journal articles, papers, and statistics.</h2>
        <p>PhD box is specialised consulting company research scholar in completion of research. Phd Box is a unit of sea sense software Pvt.Ltd and offers compreshensive product and services for research assistance.</p>
    </div>
   
        <div className='ser_pic'>
            <img src={serimg} alt="" />
        </div>
    
   </div>
   
   {/* <div className='service_div_sub'>
    <div className='service_sub_div'>
        <button>PhD SERVICE</button>
        <button>DEVELOPMENT SERVICE</button>
        <button>ADD-ON SERVICE</button>
        <button>OUR APPLICATION</button>
    </div>
    
   </div> */}
   <div className='sub_service_div'>
    <h2>PhD SERVICE</h2>
    <div className='sub_div_card'>
        <div className='main_service_card'>
            <img src={jndc} alt=""/>
            <h2>phd topic selection</h2>
            <button><img src={next} alt="" /></button>
           
        </div>
        <div className='main_service_card'>
            <img src={proposal} alt="" />
            <h2>PHD PROPOSAL</h2>
            <button><img src={next} alt="" /></button>
        </div>
        <div className='main_service_card'>
            <img src={paper} alt=""/>
            <h2>RESEARCH PAPER WRITING</h2>
            <button><img src={next} alt="" /></button>
        </div>
        </div>
       
        <div className='sub_div_card'>
          
        <div className='main_service_card'>
            <img src={journal} alt="" />
            <h2>JOURNAL ASSISTANCE</h2>
            <button><img src={next} alt="" /></button>
          
        </div>
        <div className='main_service_card'>
            <img src={synopsis} alt="" />
            <h2>SYNOPSIS PREPARATION</h2>
            <button><img src={next} alt="" /></button>
        </div>
        <div className='main_service_card'>
            <img src={thesis} alt="" />
            <h2>THESIS WRITING</h2>
            <button><img src={next} alt="" /></button>
        </div>
        </div>
       
      
    </div>


<div className='development_main_div'>
    <h2>DEVELOPMENT SERVICE</h2>
    <div className='develpom_div'>
    <div className='develpoment_div'>
        <img src={matlab} alt=""/>
        <h2>MATLAB DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
    <div className='develpoment_div'>
        <img src={java} alt="" />
        <h2>JAVA DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
    <div className='develpoment_div'>
    <img src={python} alt="" />
        <h2>PYTHON DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
</div>
<div className='develpom_div'>
    <div className='develpoment_div'>
    <img src={vlsi} alt="" />
        <h2>VLSI DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
    <div className='develpoment_div'>
    <img src={edit} alt="" />
        <h2>S-EDIT DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
    <div className='develpoment_div'>
    <img src={nd} alt="" />
        <h2>NS-2 AND NS-3 DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
</div>
<div className='develpom_div'>
    <div className='develpoment_div'>
    <img src={sil} alt="" />
        <h2>SIMULINK DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
    <div className='develpoment_div'>
    <img src={hffss} alt="" />
        <h2>HFSS DEVELOPMENT</h2>
        <button><img src={next} alt="" /></button>
    </div>
   
</div>
</div>
   <div className='add_no'>
    <h2>ADD-ON SERVICES</h2>
    <div className='add_no_main_div'>
        <div className='main_add_div'>
        <img src={pla} alt="" />
            <h2>PLAGIARISM CHECKING</h2>
            <button><img src={next} alt="" /></button>
        </div>
        <div className='main_add_div'>
        <img src={gram} alt="" />
            <h2>GRAMMAR CHECKING</h2>
            <button><img src={next} alt="" /></button>
        </div>
        <div className='main_add_div'>
        <img src={proof} alt="" />
            <h2>PROOF READING</h2>
            <button><img src={next} alt="" /></button>
        </div>

    </div>
    <div className='add_no_main_div'>
        <div className='main_add_div'>
        <img src={langu} alt="" />
            <h2>LANGUAGE CORRECTION</h2>
            <button><img src={next} alt="" /></button>
        </div>
        <div className='main_add_div'>
        <img src={litere} alt="" />
            <h2>LITERATURE REVIEW</h2>
            <button><img src={next} alt="" /></button>
        </div>
       

    </div>
   </div>
   <div className='appliction'>
    <h2>Our Application</h2>
    <div className='application_main_div'>
        <div className='appliction_content_div'>
        <img src={proof} alt="" />
<h2>INSTITUTE MANAGEMENT</h2>
<button><img src={next} alt="" /></button>
        </div>

        <div className='appliction_content_div'>
        <img src={proof} alt="" />
<h2>PAYROLL MANAGEMENT</h2>
<button><img src={next} alt="" /></button>
        </div>

    </div>
   </div>
   <Footer/>
   </>
  )
}

export default Services