import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Admin/assete/css/changepassword.css"
import Adminheader from './Adminheader';
import { updateConfirmPassword } from '../Service/Api';


function ChangePassword() {
    const navigate = useNavigate()
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleSubmit = async (event) => {
      event.preventDefault();
     
      // Check if new password matches confirm password
      if (newPassword !== confirmPassword) {
        
        setErrorMessage('New password and confirm password do not match');
        return;
      }
  
      try {
       
        
        const response = await updateConfirmPassword ( {
            id:1,
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password:confirmPassword,
         
        });
       
    
        if (response.success) {
            
            console.log('Password change successful:', response.message);
            navigate('/Adminlogin'); 
            window.location.reload();
        } else {
           
            setErrorMessage(response.message || 'Password change failed. Please try again later.');
        }
    } catch (error) {
       
        console.error('Password change failed:', error);
        setErrorMessage('Password change failed. Please try again later.');
    }
};
  return (
    <>
    <Adminheader/>
    <div className="change_pas">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Current Password"
          required
        /><br/>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          required
        /><br/>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm New Password"
          required
        /><br/>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit">Change Password</button>
      </form>
    </div>
    </>
  );
}

export default ChangePassword;
