import React from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Index from './page/Index';
import Home from './page/Home';
import Header from './page/Header';
import Footer from './page/Footer';
import Contact from './page/Contact';
import About from './page/About';
import Services from './page/Services';
import Phdproposal from './page/Phdproposal';
import Researchpaper from './page/Researchpaper';
import JouranlAss from './page/JouranlAss';
import Synopsis from './page/Synopsis';
import Thesiswriting from './page/Thesiswriting';
import Madelab from './page/Madelab';
import Java from './page/Java';
import Python from './page/Python';
import Mianpage from './page/Mianpage';
import Vlsi from './page/Vlsi';
import Plagiarism from './page/Plagiarism';
import Grammer from './page/Grammer';
import Proof from './page/Proof';
import Language from './page/Language';
import Literature from './page/Literature';
import Adminheader from './Admin/Adminheader';
import Viewdetails from './Admin/Viewdetails';
import ChangePassword from './Admin/Changepassword';
import LoginPage from './Admin/Login';
import Phdtopicselect from './page/Phdtopicselect';
import Whatapp from './page/Whatapp';
import Scrolltop from './page/Scrolltop';
import Service from './page/Service';

function App() {
  return (
    <HashRouter>
      <HelmetProvider>
        <Routes>
          {/* Index Route */}
          <Route index element={<Index />} />
          {/* Other Routes */}
          <Route path="/Home" element={<Home />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
         <Route path='Phdtopicselect' element={<Phdtopicselect/>} />
          <Route path="/Phdproposal" element={<Phdproposal />} />
       <Route path="/Researchpaper" element={<Researchpaper/>} />
        <Route path="/JouranlAss" element={<JouranlAss/>} />
        <Route path="/Synopsis" element={<Synopsis/>} />
        <Route path="/Thesiswriting" element={<Thesiswriting/>} />
        <Route path="/Madelab" element={<Madelab/>} />
        <Route path="/Java" element={<Java/>} />
        <Route path="/Python" element={<Python/>} />
        <Route path="/Mianpage" element={<Mianpage/>} />
          <Route path="*" element={<Index />} />
          <Route path="Vlsi" element={<Vlsi/>} />
          <Route path="Plagiarism" element={<Plagiarism/>} />
          <Route path='Grammer' element={<Grammer/>} />
          <Route path="Proof" element={<Proof/>} />
          <Route path="Language" element={<Language/>} />
          <Route path="Language" element={<Language/>} />
          <Route path="Literature" element={<Literature/>} />
           <Route path="Login" element={<LoginPage/>} />
            <Route path='Adminheader' element={<Adminheader/>} />
            <Route path="Viewdetails" element={<Viewdetails/>} />
            <Route path='Changepassword' element={<ChangePassword/>} />
            <Route path="Whatapp" element={<Whatapp/>} />
            <Route path="Scrolltop" element={<Scrolltop/>} />
            <Route path="Service" element={<Service/>} />
         </Routes>
      </HelmetProvider>
    </HashRouter>
  );
}
export default App;





