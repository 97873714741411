import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assate/css/header.css'; 
import logo from "../assate/logo/LOGO.png";
import { Link } from 'react-router-dom';
import {motion, useScroll} from 'framer-motion';
import { FaAngleDown, FaTimes } from 'react-icons/fa';



const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  
  const navigate = useNavigate();
  

  window.addEventListener('scroll', function() {
    var header = document.getElementById('header');
    if (window.scrollY > 0) {
      header.classList.add('blue');
    } else {
      header.classList.remove('blue');
    }
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setMenuOpen(false); 
  };

  

  

  const {scrollYProgress} =useScroll()

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
      setIsOpens(false); 
      setIsOpenss(false);
  };


  
  const [isOpens, setIsOpens] = useState(false);

  const toggleDropdowns = () => {
      setIsOpens(!isOpens);
      setIsOpen(false); 
      setIsOpenss(false);
  };
 

  const [isOpenss, setIsOpenss] = useState(false);

  const toggleDropdownss = () => {
      setIsOpenss(!isOpenss);
      setIsOpen(false); 
      setIsOpens(false);
  };
 

  const closeDropdownOnScroll = (e) => {
    if (isOpen || isOpens || isOpenss) {
      e.stopPropagation();
      setIsOpens(false);
      setIsOpen(false);
      setIsOpenss(false);
    }
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  window.addEventListener('scroll', closeDropdownOnScroll);


  const phd_peoposal = () => {
    navigate("../Index");
    navigate("../About");
    navigate("../Contact");
    navigate("../Phdtopicselect");
    navigate("../Phdproposal");
    navigate("../Researchpaper");
    navigate("../Journalass");
    navigate("../Synopsis");
    navigate("../Thesiswriting");
    navigate("../Madelab");
    navigate("../Python");
    navigate("../Java");
    navigate("../Vlsi");
    navigate("../Plagiarism");
    navigate("../Grammer");
    navigate("../Proofreading");
    navigate("../Language");
    navigate("../Literature");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };



  return (
    <>
     <motion.div
      style={{scaleX:scrollYProgress,
        position:'fixed',
        top:0,
        right:0,
        left:0,
        height:1,
        background:"blue",
        transformOrigin:"0%",
        zIndex: 9999,
    }}
    ></motion.div>
       
    <header className={`header ${menuOpen ? 'open' : ''}`} id="header">
      <div className="lg">
        <img src={logo} alt="Logo" />
      </div>
      <div className="menu-icons" onClick={toggleMenu}>
      {menuOpen ? <FaTimes style={{color:"#066fb2",fontSize:"30px"}} />  : ( // Conditionally render the close icon if menuOpen is true
            <>
              <div className="menu-icon-bar"></div>
              <div className="menu-icon-bar"></div>
              <div className="menu-icon-bar"></div>
            </>
          )}
      </div>
      <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link to="../Index"   onClick={phd_peoposal}>
              {/* <FaHome style={{ marginRight: '5px' }} />  */}
              Home
            </Link>
          </li>
          <li>
            <Link to="/About"   onClick={phd_peoposal}>
              {/* <FaInfoCircle style={{ marginRight: '5px' }} /> */}
             About
            </Link>
          </li>
          <li>
            <Link to="/Service"   onClick={phd_peoposal}>
              {/* <FaInfoCircle style={{ marginRight: '5px' }} /> */}
             Service
            </Link>
          </li>
         
          <li onMouseEnter={toggleDropdown} onMouseLeave={closeDropdown}>
             <div className="nav-item dropdown">
                                <Link to=""  className="nav-link dropdown-toggle" data-bs-toggle="dropdown"  onClick={toggleDropdown}>PhD services <FaAngleDown /></Link>
                                <div className={`dropdown-menu rounded ${isOpen ? 'show ' : ''}`} >
                                <div className='ctl' style={{background:"#066fb2",height:"5px",width:"100%",top:"0",marginTop:"-9px"}}>
                                   
                                   </div>
                                    <Link to="/Phdtopicselect"  className="dropdown-item"  onClick={phd_peoposal}>Phd Topic Selection</Link>
                                    <Link to="/Phdproposal" className="dropdown-item"  onClick={phd_peoposal}>Phd Proposal</Link>
                                    <Link to="/Researchpaper" className="dropdown-item"  onClick={phd_peoposal}>Research Paper Writting</Link>
                                    <Link to="/JouranlAss"  className="dropdown-item" onClick={phd_peoposal} >Journal Assistance</Link>
                                    <Link to="/Synopsis"  className="dropdown-item" onClick={phd_peoposal} >Synopsis Preparation</Link>
                                    <Link to="/Thesiswriting"  className="dropdown-item"  onClick={phd_peoposal}>Thesis Writing</Link>
                                </div>
                            </div> 
          </li>
          
          <li onMouseEnter={toggleDropdowns} onMouseLeave={closeDropdown}>
             <div className="nav-item dropdown">
                                <Link to=""  className="nav-link dropdown-toggle" data-bs-toggle="dropdown"  onClick={toggleDropdowns}>Develpoment service  <FaAngleDown /></Link>
                                <div className={`dropdown-menu rounded ${isOpens ? 'show' : ''}`} >
                                <div className='ctl' style={{background:"#066fb2",height:"5px",width:"100%",top:"0",marginTop:"-9px"}}>
                                   
                                   </div>
                                    <Link to="/Madelab"  className="dropdown-item" onClick={phd_peoposal}>Matlab Development</Link>
                                    <Link to="/Python" className="dropdown-item" onClick={phd_peoposal}>Python Development</Link>
                                    <Link to="/Java"  className="dropdown-item" onClick={phd_peoposal}>Java Development</Link>
                                    <Link to="/Vlsi"  className="dropdown-item" onClick={phd_peoposal}>VlSI Development</Link>
                                    <Link to=""  className="dropdown-item">S-EDIT Development</Link>
                                    <Link to=""  className="dropdown-item">NS-2 & NS-3 Development</Link>
                                    <Link to=""  className="dropdown-item">Simulink Development</Link>
                                    <Link to=""  className="dropdown-item">HFSS Development</Link>
                                </div>
                            </div> 
          </li>


          <li onMouseEnter={toggleDropdownss} onMouseLeave={closeDropdown}>
             <div className="nav-item dropdown">
                                <Link to=""  className="nav-link dropdown-toggle" data-bs-toggle="dropdown"  onClick={toggleDropdownss}>Add-On services <FaAngleDown /></Link>
                                
                                <div className={`dropdown-menu rounded ${isOpenss ? 'show' : ''}`} onClick={closeDropdownOnScroll}>
                                <div className='ctl' style={{background:"#066fb2",height:"5px",width:"100%",top:"0",marginTop:"-9px"}}>
                                   
                                   </div>
                                <Link to="/Plagiarism"  className="dropdown-item" onClick={phd_peoposal}>Plagiarism Checking</Link>
                                    <Link to="/Grammer" className="dropdown-item" onClick={phd_peoposal}>Grammer Checking</Link>
                                    <Link to="/Proof"  className="dropdown-item" onClick={phd_peoposal}>Proof Reading</Link>
                                    <Link to="/Language"  className="dropdown-item" onClick={phd_peoposal}>Language Correction</Link>
                                    <Link to="/Literature"  className="dropdown-item" onClick={phd_peoposal}>Literature Review</Link>
                                </div>
                            </div> 
          </li>
          
        
       
          
          <li>
            <Link to="/contact"  onClick={phd_peoposal}>
              {/* <FaPhoneAlt style={{ marginRight: '5px' }} />  */}
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
    </>
  );
};

export default Header;
