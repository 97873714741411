import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import "../assate/css/footer.css";
import { ContactusApi } from '../Service/Api';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { FaInstagram, FaFacebook } from 'react-icons/fa';
const Footer = () => {
  

  const [formData, setFormData] = useState({
    user_name: '',
    user_phone: '',
    user_email: '',
    service_type: '',
    user_message: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "user_name") {
     
      const onlyLetters = /^[A-Za-z\s]+$/;
      if (!onlyLetters.test(value)) {
        setErrorMessage("only accept name field.");
        return;
      }
    }

    else if (id === "user_phone") {
      const onlyNumbers = /^[0-9]*$/;
      if (!onlyNumbers.test(value)) {
        setErrorMessage("Invalid Phone Number");
        return;
      }
    }
     else if (id === "user_email") {
      
      if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
        setErrorMessage("Invalid email address.");
      } else {
        setErrorMessage(""); 
      }
    }
  
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async () => {
    if (
      formData.user_name.trim() === '' ||
      formData.user_phone.trim() === '' ||
      formData.user_email.trim() === '' ||
      formData.service_type.trim() === '' ||
      formData.user_message.trim() === ''
    ) {
      setErrorMessage('All fields are required');
      setTimeout(() => {
        
        setErrorMessage('');
      }, 5000);
      return;
      
    }
    if (!recaptchaToken) {
      setErrorMessage('Please complete the reCAPTCHA');
      return;
    }
    try {

      setErrorMessage('');
      const response = await ContactusApi(formData, recaptchaToken);
      console.log('Form data saved:', response.data);
      setSuccessMessage('submitted successfully!');
      setFormData({
        user_name: '',
        user_phone: '',
        user_email: '',
        service_type: '',
        user_message: ''
      });
      setTimeout(() => {
        
        setSuccessMessage('');
      }, 2000);
    
    } catch (error) {
      console.error('Error saving form data:', error);
      
    }
  };


  const navigate = useNavigate();
  const phd_peoposal = () => {
    navigate("../Index");
    navigate("../About");
    navigate("../Contact");
    navigate("../Phdtopicselect");
    navigate("../Phdproposal");
    navigate("../Researchpaper");
    navigate("../Journalass");
    navigate("../Synopsis");
    navigate("../Thesiswriting");
    navigate("../Madelab");
    navigate("../Python");
    navigate("../Java");
    navigate("../Vlsi");
    navigate("../Plagiarism");
    navigate("../Grammer");
    navigate("../Proofreading");
    navigate("../Language");
    navigate("../Literature");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
    <footer>
      
      <div className='footer_main_div'>
      <div className="footer-menu">
     <h2 data-aos="fade-down">Submit Your Enquiry</h2>
        <div className='foot_sub_head'>
      <label data-aos="fade-down">
      
        <input type="text" placeholder='Enter your Name' id="user_name" value={formData.user_name} onChange={handleChange}  ></input>
      </label>
      <label data-aos="fade-down">
       
        <input type="text" placeholder='Enter Your Number' id="user_phone" value={formData.user_phone} onChange={handleChange} maxLength="10"  pattern="[0-9]*"  inputMode="numeric"></input>
      </label>
      <label data-aos="fade-down">
       
        <input type="email" placeholder='Enter Your Email' id="user_email" value={formData.user_email} onChange={handleChange} ></input>
      </label>
      <label data-aos="fade-down">
       
        <select id="service_type" value={formData.service_type} onChange={handleChange}>
            <option value="">select</option>
            <option value="Research Proposal">Research Proposal</option>
            <option value="Research Paper">Research Paper</option>
            <option value="Implementation">Implementation</option>
            <option value="Publication Assistance">Publication Assistance</option>
            <option value="Polishing">Polishing</option>
            <option value="Web Hosting">Web Hosting</option>
            <option value="Email Service">Email Service</option>
            <option value="SMS Service">SMS Service</option>
            <option value="Student Management">Student Management</option>
            <option value="Employee Payroll">Employee Payroll</option>
            <option value="other">Other</option>
        </select>
      </label>
      <label data-aos="fade-down">
       
              <textarea type="text" placeholder='Enter Your Message' id='user_message' value={formData.user_message} onChange={handleChange}></textarea>
        </label>
        <label  data-aos="fade-down">
        <ReCAPTCHA
    sitekey="6Lc19s4pAAAAAGgxILFR3el4jvlsZeRX7qbFAJk4"
    onChange={onRecaptchaChange}
  />
  </label>
        </div>
        {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
        {successMessage && <div className="success-message" style={{ color: 'red' }}>{successMessage}</div>}
        <div className='enquri_btn' data-aos="fade-down">
          <button onClick={handleSubmit}>Submit</button>
        </div>
      

      </div>
     <div className='fooder_sub_menus' >
      <div className='footer_menus'>
        <h2 data-aos="fade-down">Our Resources </h2>
        <p data-aos="fade-down"><Link to="/About" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>About us</Link></p>
        <p data-aos="fade-down"><Link to="#" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Services</Link></p>
        <p data-aos="fade-down"><Link to="/Phdtopicselect" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Phd Topic Selection</Link></p>
        <p data-aos="fade-down"><Link to="/Phdproposal" style={{color:"#fff",textDecoration:"none"}}>Phd Proposal</Link></p>
        <p data-aos="fade-down"><Link to="/Researchpaper" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Research Paper Writing</Link></p>
        <p data-aos="fade-down"><Link to="/JouranlAss" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Journal Assistance</Link></p>
        <p data-aos="fade-down"><Link to="/Synopsis" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Synopsis Preparation</Link></p>
        <p data-aos="fade-down"><Link to="/Thesiswriting" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Thesis Writing</Link></p>
        <p data-aos="fade-down"><Link to="/Contact" style={{color:"#fff",textDecoration:"none"}} onClick={phd_peoposal}>Contact</Link></p>

      </div>
    </div>
     <div className='offz_footer'>
      <div className='offz_sub_head' >
     <h2 data-aos="fade-down">Our Branches </h2>
     <p data-aos="fade-down">Sea sense Group of Companies</p>
     <p data-aos="fade-down">Sea Sense Technologies</p>
     <p data-aos="fade-down">Alleppey - Kerala - 688 001, INDIA</p>
     <div className='branches'>
      <h2 data-aos="fade-down">Head Office,</h2>
      <p data-aos="fade-down">Sea Sense Softwares</p>
      <p data-aos="fade-down">JJ Arcade, Second Floor,  Near New Bus Stand</p>
      <p data-aos="fade-down">Marthandam - Kanyakumari District - TamilNadu - 629 165, INDIA</p>
      <p data-aos="fade-down">Mob : +91-7402616151</p>
      <p data-aos="fade-down">Phone : 04651-271057</p>
     </div>
     <div className='social-icons' data-aos="fade-down">
          <a href="https://www.instagram.com/sea_sense_research?igsh=MWVnYTNxZ3Noc3A1bg=="><FaInstagram /></a>
          <a href="https://www.facebook.com/seasensesoftwares/"><FaFacebook /></a>
          {/* <a href="https://twitter.com/your-twitter-account"><FaTwitter /></a> */}
        </div>
      </div>
     </div>
     </div>
     
    
    </footer>
    <div className='foot_final'>
    <h2>Copyright © 2020 - 2025 SEA SENSE | All Right Reserved</h2>
   </div>
   </>
  );
}

export default Footer;
