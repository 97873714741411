import React from 'react'
import Header from './Header';
import "../assate/css/synopsis.css";
import sy_pic from "../assate/sub_image/ban.jpg";
import dyn_pic_1 from "../assate/sub_image/WRITING WINNING PROPOSAL.png";
import sy_pic_1 from "../assate/newwebpic/syno.png";
import Footer from './Footer';
import Whatapp from './Whatapp';
import Scrolltop from './Scrolltop';

const Synopsis = () => {
  return (
  <>
  <body>
  <Header/>
  <div className='synopsis_main_div'>
    <div className='synposis_pic'>
        <img src={sy_pic} alt="" />
        <div className='content-overlay '>
          <h1>SYNOPSIS PREPARATION</h1>
        </div>
    </div>
  </div>

  <div className='syno_di'>
  <h2 data-aos="fade-down">SYNOPSIS PREPARATION</h2>
        <p  data-aos="fade-down">Synopsis is the blueprint of your PhD thesis work that guarantees thorough planning and professional assistance.</p>
        <p  data-aos="fade-down">Synopsis need to justify the good qualities of your research project and must convince the committee members for getting their approval for the project.</p>
  </div>
  <div className='synposis_content_div'>
    <div className='synopsis_content_div_1'>
       
        <h2 data-aos="fade-down" style={{fontSize:"20px"}}>Research project synopsis comprise of the following :</h2>
        <ul data-aos="fade-down">
          <li>Overview of Thesis</li>
          <li>Motivation and Problem Statements</li>
          <li>Literature Review</li>
          <li>Data collection</li>
          <li>Research Methodology</li>
          <li>Statistical Analysis</li>
          <li>Conclusion</li>
          <li> Research Deadlines</li>
          <li> Issues and Challenges</li>
        </ul>
    </div>
    <div className='synopsis_pic' data-aos="flip-left">
        <img src={dyn_pic_1} alt="" />
    </div>
  </div>
  <div className='synopsis_sub_content_div'>
    <p data-aos="fade-down">Making a synopsis is a difficult task. A synopsis is a systematic summary or simplified version of anticipated research that is created before research begins. The research overview summarizes the overall design of the study. It defines why the research is being conducted, what the goals are, and how the data will be collected. It can also be used to collect and track guides and inquiries. Seasense provides the best synopsis writing service to help you achieve your project goals.</p>
    <p data-aos="fade-down">Synopsis - The most important document that we can create before we proceed with your actual thesis is a thesis proposal. Because whether or not the thesis is allowed to be written is determined by the proposal or the synopsis. The summary of the thesis or the writing of thesis proposals for the approval / admission of phd takes into account the importance of the proposal and contains all the necessary details.</p>
  </div>
  <div className='synopsis_con_sub_div'>
    <h2 data-aos="fade-down">PhD synopses are created with the greatest care and follow the exact requirements and specifications of your university</h2>
    <p data-aos="fade-down">
The style, design and format of an ideal dissertation required by universities are managed and prepared by Seasense PhD Synopsis Writing Service. We demonstrate knowledge and understanding beyond the bachelor's level and keep the scope and complexity of the material covered in your class to a minimum. We help you finalize your synopsis, which includes a topic, writing research proposals, reflective journals for your thesis, introduction, literature research, research methods, presentation and discussion of results, research ethics, quotations and references, and the appendices. To build precise reasoning, we follows a clearly structured approach to validating and justifying facts, views, and theories.</p>
<h2 data-aos="fade-down">Is there a standard format for a PhD thesis?</h2>
<p data-aos="fade-down">It is important that you follow the content format recommended by your academic institution when completing research-related documents. While you are familiar with the structure and format of a thesis, you should also be aware that your synopsis follows a similar format. If you deviate from this standard format, your work can be rejected entirely.</p>
  </div>
  <div className='syno_fl_div'>
    <div className='syno_pic_div' data-aos="fade-right">
      <img src={sy_pic_1} alt="" />
    </div>
  <div className='synopsis_con_sub_div_1'>
    <h2 data-aos="fade-down">How We Help You Format Your Synopsis</h2>
    <p data-aos="fade-down">
Our editors not only will help you fix the above deficiencies in your PhD summary format, but they will also assist you in structuring your work within the word restrictions and under different sections. They make sure that you follow all of your institution's formatting guidelines. Our editors, who provide APA formatting services, are familiar with a variety of professional formatting standards and can help you accurately cite your sources. Our services support you in delivering an appealing and concise overview on time.</p>
<p data-aos="fade-down">Our experienced writers provide PhD synopsis writing services from an extensive library of materials so that you can select the most current and relevant references for your literature research. We will also help you determine the amount of information you need, the sources from which you obtain it, the sample size and composition of the study participants, the data collection media, and the data analysis methods to create a viable and unique research design with no validity issues.</p>
<p data-aos="fade-down">You can also have a longer conversation with one of our expert to develop a schedule for your research project. We all know how important the presentation of the synopsis is. We therefore follow to the exact formatting and citation style. We guarantee a writing assistance for research summaries with a high degree of scientific language and 100% original content.</p>
  </div>
  </div>
  <Whatapp/>
  <Scrolltop/>
  <Footer/>
  </body>
  </>
  )
}

export default Synopsis;